import Api from "../api/Api"
import { PropertySummary } from "../queries/userData"
import { ExportBalanceRequest, ExportExpensesRequest, ExportIncomeRequest, ExportReadingRequest } from "../sections/expenses/export/ExportForm"

export interface DownloadMultiFile {
    ids: string[]
    name: string
    extension?: string
}

export interface DownloadInfo {
    downloadId: string
    downloadName: string
    extension?: string
}

export interface DownloadRentingPeriodInfo extends DownloadInfo {
    downloadKey: string
}

export interface DownloadFolder {
    propertyId: number
    downloadName: string
}

export enum ExportType {
    Expense,
    Income,
    Reading,
    Balance,
}

export const processDownloadData = (data: any, name: string, extension = "zip", isPending?: boolean) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", isPending ? `${name}` : `${name}.${extension}`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode?.removeChild(link)
}

export const downloadFile = async (fileToDownload: DownloadInfo) => {
    const data = await Api.downloadMultiFile([fileToDownload.downloadId])
    processDownloadData(data, fileToDownload.downloadName, fileToDownload.extension)
}

export const exportData = async (
    data: ExportExpensesRequest | ExportIncomeRequest | ExportReadingRequest | ExportBalanceRequest,
    type: ExportType,
) => {
    let result: unknown
    if (type === ExportType.Expense) {
        result = await Api.downloadExpenseExport(data as ExportExpensesRequest)
    }
    if (type === ExportType.Income) {
        result = await Api.downloadIncomeExport(data as ExportIncomeRequest)
    }
    if (type === ExportType.Reading) {
        result = await Api.downloadReadingExport(data as ExportReadingRequest)
    }
    if (type === ExportType.Balance) {
        result = await Api.downloadTenantBalanceReport(data as ExportBalanceRequest)
    }
    return result
}

export const downloadMultiFile = async (fileToDownload: DownloadMultiFile) => {
    const data = await Api.downloadMultiFile(fileToDownload.ids)
    processDownloadData(data, fileToDownload.name, fileToDownload.extension)
}

export const downloadRentingPeriodData = async (fileToDownload: DownloadRentingPeriodInfo) => {
    const data = await Api.downloadRentingPeriodData(fileToDownload.downloadId, fileToDownload.downloadKey)
    processDownloadData(data, fileToDownload.downloadName, fileToDownload.extension)
}

export const getOwnerMaxSize = (
    userProperties: PropertySummary[] | undefined,
    maxPropertySize: number | undefined,
    propertyId: number | undefined,
) => {
    if (userProperties == null || maxPropertySize == null) return
    const property = userProperties.find((p) => p.id === propertyId)

    if (property == null) return 0
    return maxPropertySize - property.currentSize
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
    })
}
