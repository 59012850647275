import { CustomDataGrid, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useCallback, useMemo, useState } from "react"
import {
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridActionsCellItem,
    GridColDef,
    GridFilterModel,
    GridRowParams,
    GridSortModel,
} from "@mui/x-data-grid-pro"
import { RoleName, useUserPropertiesQuery } from "../../../queries/userData"
import { DateTime } from "luxon"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { InventorySection } from "../../../redux/slices/AddProperty"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import NoDataPage from "../../../components/NoDataPage"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import CustomRoleBlockedToolbar from "../../../components/CustomRoleBlockedToolbar"
import CustomDetailPanelToggle from "../../expenses/CustomDetailPanelToggle"
import { useInventorySectionsQueryFn } from "../../../queries/inventory"
import InventorySectionDetailsPanel from "./InventorySectionDetailsPanel"

export default function DesktopInventorySectionsList() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const { currentPropertyId } = useSelector((state) => state.appState)
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "createdAt", sort: "desc" }])
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
    const { data: revisions, isFetching } = useInventorySectionsQueryFn(
        currentPropertyId,
        paginationModel.page,
        paginationModel.pageSize,
        sortModel,
        filterModel,
    )
    const { data: userProperties } = useUserPropertiesQuery()
    const { canWrite } = usePermissions(PermissionType.InventorySections)

    const getObservationsPanel = useCallback((row: GridRowParams<InventorySection & { canWrite: boolean; canDelete: boolean }>) => {
        return <InventorySectionDetailsPanel currentInventorySection={row.row} />
    }, [])

    const columns = useMemo(() => {
        const columns: GridColDef<InventorySection>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return <CustomDetailPanelToggle id={params.id} value={params.value} title={"see_inventory_items"} />
                },
            },
            {
                field: "sectionName",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("section_name"),
            },
            {
                field: "numberOfItems",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("number_of_items"),
            },
            {
                field: "createdByUser",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("added_by"),
                sortable: false,
            },
            {
                field: "createdAt",
                type: "date",
                width: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "lastModifiedAt",
                type: "date",
                width: 170,
                headerAlign: "center",
                align: "center",
                headerName: translate("lastModifiedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [userProperties, translate])

    const actionsColumn: GridColDef<InventorySection> = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            width: 130,
            disableReorder: true,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams<InventorySection & { canWrite: boolean; canDelete: boolean }>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ inventorySectionId: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditInventorySection))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden: !params.row.canWrite,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ inventorySectionId: params.row.id }))
                                dispatch(setModalOpen(AppModals.DeleteInventorySection))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: "",
                    key: "delete",
                    hidden: !params.row.canDelete,
                }),
            ],
        }),
        [translate, dispatch],
    )

    return (
        <>
            {canWrite && (
                <CustomRoleBlockedToolbar
                    onAddClick={() => dispatch(setModalOpen(AppModals.AddInventorySection))}
                    buttonText={"add_inventory_section"}
                    blockedRoles={[RoleName.FREE, RoleName.BRONZE]}
                />
            )}

            <CustomDataGrid
                paginationMode={"server"}
                isLoading={isFetching}
                columns={columns}
                rows={revisions?.items ?? []}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:box-remove"} noDataText={"no_inventory_sections"} />,
                }}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={revisions?.count}
                actionsColumn={[actionsColumn]}
                onFilterModelChange={setFilterModel}
                filterModel={filterModel}
                onPaginationModelChange={setPaginationModel}
                getRowId={(row) => row.id}
                getDetailPanelContent={getObservationsPanel}
                gridId='DesktopInventorySectionList'
            />
        </>
    )
}
