import { Box, Button } from "@mui/material"
import ChooseCardPage from "../../income/add-income/ChooseCardPage"
import React, { useCallback } from "react"
import { useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import { useUserDataQueryFn } from "../../../queries/userData"
import { useSingleExpenseQuery } from "../../../queries/expenses"

interface Props {
    onClose: () => void
}
export default function ChoosePaymentType({ onClose }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { data: user } = useUserDataQueryFn()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)

    const handleOnlinePayment = useCallback(() => {
        if (user?.hasCardPaymentDetails) {
            dispatch(setModalOpen(AppModals.ExpensePayment))
            dispatch(setEditingItem({ ...editingItem, choosePayment: true }))
        } else {
            dispatch(setModalOpen(AppModals.AddCardPaymentData))
        }
    }, [user, dispatch, editingItem])

    const handleUploadExpenseInvoice = useCallback(() => {
        dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsTenant))
        dispatch(setEditingItem({ ...editingItem, choosePayment: true }))
    }, [dispatch, editingItem])

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={2}>
            <>
                <ChooseCardPage
                    title={"pay_this_expense"}
                    icon={"mdi:account-payment-outline"}
                    onClick={handleOnlinePayment}
                    isDisabled={!currentExpense?.canPayOnline}
                    disabledTooltipText={"this_expense_can_not_be_paid"}
                />
                <ChooseCardPage title={"upload_payment_confirmation"} icon={"mdi:invoice-text-plus-outline"} onClick={handleUploadExpenseInvoice} />
            </>

            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingX: 0, paddingTop: 2 }}>
                <Button color={"primary"} onClick={onClose} fullWidth={isMobile} variant={isMobile ? "contained" : "text"}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
