import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { CustomPopover, IKanbanTask, useLocales } from "rentzz"
import { useAtom } from "jotai/index"
import { taskSectionFilterAtom } from "../../../utils/atom"
import { useCallback, useMemo } from "react"

export interface SectionFilterPickerProps {
    anchorEl: any
    open: boolean
    sectionId: string
    onClose: () => void
}

const filterOptions: (keyof IKanbanTask)[] = ["deadline", "createdAt"]

const SectionFilterPicker = ({ anchorEl, open, sectionId, onClose }: SectionFilterPickerProps) => {
    const [sectionFilters, setSectionFilters] = useAtom(taskSectionFilterAtom)
    const currentSectionFilter = useMemo(() => sectionFilters[sectionId], [sectionId, sectionFilters])
    const { translate } = useLocales()
    const handleRadioChange = useCallback(
        (_: unknown, newValue: string) => {
            setSectionFilters((prev) => {
                localStorage.setItem("taskSection_sort", JSON.stringify({ ...prev, [sectionId]: newValue as keyof IKanbanTask }))

                return { ...prev, [sectionId]: newValue as keyof IKanbanTask }
            })
        },
        [setSectionFilters, sectionId],
    )

    return (
        <CustomPopover open={open} anchorEl={anchorEl} onClose={onClose}>
            <Box height={80} width={250}>
                <FormControl>
                    <FormLabel id={`${sectionId}-section-filter`}></FormLabel>
                    <RadioGroup
                        onChange={handleRadioChange}
                        aria-labelledby={`${sectionId}-section-filter`}
                        defaultValue={currentSectionFilter ?? "deadline"}
                    >
                        {filterOptions.map((option) => (
                            <FormControlLabel key={option} value={option} control={<Radio />} label={translate(`task_section_filter-${option}`)} />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Box>
        </CustomPopover>
    )
}

export default SectionFilterPicker
