import axios from "axios"
import { QueryFunctionContext } from "@tanstack/react-query"
import {
    ConfiguredExpenseProvider,
    ConfiguredExpenseProviderDto,
    DashboardChart,
    DashboardChartDto,
    DashboardMetersTable,
    DashboardMetersTableDto,
    ExpenseLateFeesDto,
    ExpenseLateFeesResponse,
    ExpenseProvider,
    InventoryItem,
    InventoryItemDto,
    InventorySection,
    InventorySectionDto,
    MeterProvider,
    OwnerPropertyMeter,
    OwnerPropertyMeterDto,
    PropertyData,
    PropertyDataDto,
    PropertyFileDto,
    PropertyFilesGrouped,
    PropertyFileType,
    PropertyIncome,
    PropertyIncomeDto,
    PropertyInsurance,
    PropertyInsuranceDto,
    PropertyMeterValue,
    PropertyMeterValueDto,
    PropertyRevision,
    PropertyRevisionDto,
    RentingPeriod,
    RentingPeriodDetails,
    RentingPeriodDetailsDto,
    RentingPeriodDto,
    RentingPeriodSummaryData,
    RevisionObservationDto,
    SummaryCardDto,
    TenantPropertyMeter,
    TenantPropertyMeterDto,
    TenantRevision,
    TenantRevisionDto,
} from "../redux/slices/AddProperty"
import {
    AppContext,
    CommentType,
    CommentTypeDto,
    CountyCityStreetType,
    CustomFile,
    ExpenseAssignee,
    HealthCheck,
    ICalendarEvent,
    IKanbanSection,
    IKanbanSectionDto,
    IKanbanTask,
    IKanbanTaskDto,
    PersonalData,
    PromoCodeDetails,
    PromoCodeRequest,
    SearchResult,
    TaskAssignedUser,
    Unit,
} from "rentzz"
import {
    AccountType,
    ActiveUser,
    ActiveUserDto,
    BillingDetails,
    BillingDetailsDto,
    CustomProvider,
    CustomProviderDto,
    DashboardChartType,
    LabelDescription,
    LabelDescriptionDto,
    LabelType,
    ManagerRequestDto,
    NotificationTemplate,
    NotificationTemplateDetails,
    NotificationTemplateDto,
    NotificationTemplateVariable,
    PropertySummary,
    ProviderDetail,
    SummaryCardType,
    TenantRentingPeriod,
    TenantRentingPeriodDto,
    TenantRequestDto,
    UserBankAccount,
    UserBankAccountDto,
    UserContractTemplate,
    UserContractTemplateDto,
    UserData,
    UserDataDto,
    UserInvoice,
    UserNotifications,
    UsersWithPromoCode,
    UsersWithPromoCodeDto,
} from "../queries/userData"
import { DateTime } from "luxon"
import * as jsonpatch from "fast-json-patch"
import { Currency } from "../queries/currency"
import { fromIsoToDateTime } from "../utils/dateMagic"
import { AddDocumentsRequest } from "../sections/files/AddDocumentForm"
import { SubscriptionDto } from "../queries/subscription"
import { ContractData } from "../sections/contracts/newContract/Wizard"
import { TenantWithContract } from "../sections/tenantContext/rentingPeriodNotification/wizard/RentingPeriodWizard"
import { AddInsuranceRequest } from "../sections/insurance/AddInsurance"
import { CreateNewLabelRequest } from "../sections/nomenclature/labels/AddOrEditNewLabel"
import { ProfileChangeRequest } from "../sections/settings/general/GeneralProfile"
import { AddIncomeRequest } from "../sections/income/add-income/types"
import { AddFileToEntityRequest } from "../sections/files/EntityFileManagerForm"
import { AddMeterRequest } from "../sections/propertyDetails/Meters/AddMeterForm"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { AddMessageRequest } from "../guards/alertsGuard/contact/types"
import { Notes, NotesDto } from "../queries/notes"
import { AddNoteRequest } from "../sections/propertyDetails/General/details/notes/AddNoteForm"
import { PaginatedData, PropertyExpense, PropertyExpenseDto, RecurringExpense, RecurringExpenseDto } from "../queries/expenses"
import { AddRecurringExpenseRequest, GenerateExpenseLateFeesRequest } from "../mutations/expenses"
import { max, min, orderBy, uniq } from "lodash"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { CreatePropertyRequest } from "../dialogs/add-edit-property/steps/MapPicker"
import { AddProviderRequest } from "../sections/propertyDetails/configurations/providers/addProvider/types"
import { AddMeterValueRequest } from "../sections/propertyDetails/Meters/MetersHistory/Toolbar"
import { ExportBalanceRequest, ExportExpensesRequest, ExportIncomeRequest, ExportReadingRequest } from "../sections/expenses/export/ExportForm"
import i18n from "../locales/i18n"
import { ExpensesFilteredOptions, IncomesFilteredOptions, RentingPeriodsFilteredOptions, TasksFilterOptions } from "../utils/atom"
import { growthbook } from "../index"
import { CreateNewProviderRequest } from "../sections/nomenclature/providers/AddOrEditNewProvider"
import { AddSectionRequest } from "../sections/nomenclature/sections/AddOrEditSectionForm"
import { AddTenantRequest } from "../sections/types/AddTenant"
import { AddTaskRequest, TenantAddTaskRequest } from "../sections/kanban/AddTaskForm"
import { OwnerAddTaskCommentRequest, TenantAddTaskCommentRequest } from "../sections/kanban/details/TaskDetailsCommentInput"
import { Journal, JournalDto } from "../queries/journal"
import { AddJournalRequest } from "../sections/propertyDetails/Tenants/rentingPeriodDetails/journal/AddOrEditJournalForm"
import { ExpirationReason } from "../sections/tenantContext/ShowExpiration"
import {
    InvoicingConfiguration,
    InvoicingConfigurationClient,
    InvoicingConfigurationClientDto,
    InvoicingConfigurationCompany,
    InvoicingConfigurationCompanyDto,
    InvoicingConfigurationDto,
    InvoicingConfigurationSeries,
    InvoicingConfigurationSeriesDto,
    InvoicingConfigurationUser,
    InvoicingConfigurationUserDto,
    InvoicingConfigurationVAT,
    InvoicingDryRun,
    InvoicingDryRunDto,
    PendingInvoiceStatusDto,
    PendingInvoiceStatusResponse,
} from "../queries/invoicing-configuration"
import { AddNewInvoicingConfigurationRequest, GenerateQueuedInvoiceDto, GenerateQueuedInvoiceResponse } from "../mutations/invoicingConfiguration"
import { AddRevisionRequest } from "../sections/propertyDetails/revision/AddOrEditRevisionForm"
import { AddGroupRequest } from "../sections/groups/AddGroupForm"
import { AddPaymentRequest } from "../sections/tenantContext/payment/AddPaymentForm"
import { GeneralDocument, GeneralDocumentDetails, GeneralDocumentDto } from "../queries/generalDocuments"
import { AddNewDocumentRequest } from "../sections/documents-templates/generalDocuments/AddOrEditGeneralDocument"
import { AddBankAccountRequest } from "../sections/nomenclature/bankAccounts/AddBankAccountForm"
import { AddExpenseRequest } from "../sections/expenses/AddExpenseForm"
import {
    DashboardConfigDetails,
    GroupAccess,
    GroupAccessDto,
    GroupRentingPeriod,
    GroupRentingPeriodDto,
    UserGroup,
    UserGroupDto,
} from "../queries/groups"
import { AddCardPaymentDataRequest } from "../sections/expenses/online-payment/AddCardPaymentDataForm"
import { UploadExpenseExtractorAI, UploadExpenseExtractorAIResponse, UploadExpenseExtractorAIResponseDto } from "../mutations/property/documents"
import { AddObservationRequest } from "../sections/propertyDetails/revision/AddObservationForm"
import { SyncImoCRMAgencyIdRequest } from "../pages/properties/SyncPropertiesForm"
import { PresentationFile, ProviderWithoutExpenseThisMonth, ProviderWithoutExpenseThisMonthDto } from "../queries"
import { AddPhotoToPropertyRequest } from "../mutations"
import { AddBankStatementRequest, AssociateExpenseToStatementTransactionRequest } from "../mutations/bankAccounts"
import {
    BankStatement,
    BankStatementDetails,
    BankStatementDetailsDto,
    BankStatementDto,
    BankStatementTransactionDetails,
    BankStatementTransactionDetailsDto,
} from "../queries/bank-statements/types"
import { UploadProfilePictureRequest } from "../mutations/user"
import { C168AddressDto, C168AddressFinal } from "../queries/c168"
import { AddNewInvoicingClientRequest } from "../mutations/invoicingClient"
import { v4 as uuidv4 } from "uuid"
import { AddGroupDashboardConfigurationRequest } from "../sections/groups/groupSection/AddGroupDashboardConfigurationForm"
import { AddInventorySectionRequest } from "../sections/propertyDetails/inventory/AddOrEditInventorySectionForm"
import { AddInventorySectionItemRequest } from "../sections/propertyDetails/inventory/AddOrEditInventorySectionItemForm"

export default class Api {
    public static API = ""
    private static CONTEXT: AppContext | undefined =
        localStorage.getItem("appContext") != null ? (localStorage.getItem("appContext") === "0" ? AppContext.Owner : AppContext.Tenant) : undefined

    public static fetchConfig() {
        return Api.get<Record<string, string>>("/config")
    }

    public static setContext(context: AppContext) {
        Api.CONTEXT = context
    }

    public static async fetchCountiesC168(): Promise<CountyCityStreetType[]> {
        const response = await axios.get("https://api.c168.ro/nomenclature/counties")
        return response.data
    }

    public static async fetchCitiesC168({ queryKey }: QueryFunctionContext): Promise<CountyCityStreetType[]> {
        const [_, data] = queryKey
        const { countyId } = data as { countyId: string | undefined }
        const response = await axios.get(`https://api.c168.ro/nomenclature/counties/${countyId}/cities`)
        return response.data
    }

    public static async fetchStreetsC168({ queryKey }: QueryFunctionContext): Promise<CountyCityStreetType[]> {
        const [_, data] = queryKey
        const { cityId } = data as { cityId: string | undefined }
        const response = await axios.get(`https://api.c168.ro/nomenclature/cities/${cityId}/streets`)
        return response.data
    }

    public static async register2() {
        await this.post<unknown>("/user/create", true)
    }

    public static async createProperty(data: CreatePropertyRequest) {
        const formData = new FormData()
        if (data.details?.city != null) formData.set("cityId", data.details?.city.toString())
        if (data.internalId != null && data.internalId !== "") formData.set("internalId", data.internalId.toString())
        if (data.details?.county != null) formData.set("countyId", data.details.county.toString())
        if (data.details?.area != null) formData.set("areaId", data.details.area.toString())
        if (data.details?.street != null) formData.set("street", data.details.street)
        if (data.details?.streetNumber != null) formData.set("streetNumber", data.details.streetNumber)
        if (data.details?.buildingNumber != null) formData.set("buildingNumber", data.details.buildingNumber)
        if (data.details?.stair != null) formData.set("stair", data.details.stair)
        if (data.details?.apartment != null) formData.set("apartment", data.details.apartment)
        if (data.details?.rooms != null) formData.set("rooms", data.details.rooms.toString())
        if (data.propertyName) formData.set("name", data.propertyName)
        if (data.labelId) formData.set("labelId", data.labelId.toString())
        if (data.details?.floor != null) formData.set("floor", data.details.floor.toString())
        if (data.details?.year != null) formData.set("year", data.details.year.toString())
        if (data.details?.size != null) formData.set("size", data.details.size.toString())
        if (data.location?.lat != null) formData.set("lat", data.location.lat.toString())
        if (data.location?.lng != null) formData.set("lng", data.location.lng.toString())
        if (data.photos != null) data.photos.forEach((f) => formData.append("photos", f))
        await this.post("/property", formData)
    }

    public static async uploadFileToData(data: AddFileToEntityRequest) {
        const formData = new FormData()

        formData.set("uploadType", data.uploadType.toString())
        formData.set("referenceId", data.referenceId.toString())
        data.files.forEach((f) => f != null && formData.append("files", f))

        await this.post("/files", formData)
    }

    public static async uploadPhotoToProperty(data: AddPhotoToPropertyRequest) {
        const formData = new FormData()
        data.presentationFiles.forEach((f) => f != null && "path" in f && formData.append("files", f))

        await this.post(`/property/${data.propertyId}/presentation-files`, formData)
    }

    public static async updatePropertyCoverPhoto(files: PresentationFile[], propertyId: number) {
        const formData = new FormData()

        files.forEach((file: { id: string; position: number }, index) => {
            formData.set(`files[${index}].id`, file.id.toString())
            formData.set(`files[${index}].position`, file.position.toString())
        })

        await this.put(`/property/${propertyId}/presentation-files`, formData)
    }

    public static async fetchUserData(): Promise<UserData | null> {
        const response = await Api.get<UserDataDto>("/user")

        return {
            ...response,
            roles: {
                ...response.roles,
                from: response.roles.from ? fromIsoToDateTime(response.roles.from) : undefined,
                to: response.roles.to ? fromIsoToDateTime(response.roles.to) : undefined,
            },
        }
    }

    public static async fetchUserProperties(): Promise<PropertySummary[]> {
        return Api.get<PropertySummary[]>("/property")
    }

    public static async fetchUserLabels({ queryKey }: QueryFunctionContext): Promise<LabelDescription[]> {
        const [_, data] = queryKey
        const { type } = data as { type: LabelType | "all" }
        let filterBy = ""
        if (type !== "all") {
            filterBy = `&$filter=type eq '${Object.keys(LabelType)[Object.values(LabelType).indexOf(type)]}'`
        }

        const response = await Api.get<LabelDescriptionDto[]>(`/user/labels?$orderBy=id desc${filterBy}`)

        return response.map((r) => ({
            ...r,
            createdAt: fromIsoToDateTime(r.createdAt),
            lastModifiedAt: fromIsoToDateTime(r.lastModifiedAt),
        }))
    }

    public static async fetchUserBankAccounts({ queryKey }: QueryFunctionContext): Promise<PaginatedData<UserBankAccount>> {
        const [_, data] = queryKey
        const { propertyId } = data as { propertyId: number }
        let url = "/nomenclature/bank-account"
        if (propertyId) {
            url += `?$filter=properties/any(p:p eq ${propertyId})`
        }
        const response = await Api.get<PaginatedData<UserBankAccountDto>>(url)

        return {
            items: response.items.map((r) => ({
                ...r,
                createdAt: fromIsoToDateTime(r.createdAt),
                lastModifiedAt: fromIsoToDateTime(r.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchPublicGroupConfiguration({ queryKey }: QueryFunctionContext): Promise<DashboardConfigDetails> {
        const [_, data] = queryKey
        const { id, idp } = data as { id: string; idp: string }
        const url = `/groups/p/dashboard-configurations?id=${id}&idp=${idp}`

        const response = await Api.get<DashboardConfigDetails>(url)

        return response
    }

    public static async fetchUserNotifications(): Promise<UserNotifications> {
        return Api.get<UserNotifications>("/user/notifications")
    }

    public static async fetchUserProviders(): Promise<CustomProvider[]> {
        const response = await Api.get<CustomProviderDto[]>("/nomenclature/providers?$orderBy=id desc")
        return response.map((r) => ({
            ...r,
            createdAt: fromIsoToDateTime(r.createdAt),
            lastModifiedAt: fromIsoToDateTime(r.lastModifiedAt),
        }))
    }

    public static async fetchProviderDetails({ queryKey }: QueryFunctionContext): Promise<ProviderDetail[]> {
        const [_, data] = queryKey
        const { providerId } = data as { providerId: number }
        const result = await Api.get<ProviderDetail[]>(`/nomenclature/providers/${providerId}/expense-providers`)

        return result
    }

    public static async fetchC186Addresses(): Promise<PaginatedData<C168AddressFinal>> {
        const result = await Api.get<PaginatedData<C168AddressDto>>("/nomenclature/c168")
        return {
            items: result.items.map((dto) => ({
                ...dto,
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
                createdAt: fromIsoToDateTime(dto.createdAt),
            })),
            count: result.count,
        }
    }

    public static async fetchUserCardPaymentDetails(): Promise<AddCardPaymentDataRequest> {
        return Api.get<AddCardPaymentDataRequest>("/user/card-payment-details")
    }

    public static async fetchAvailableSummaryCards(): Promise<SummaryCardType[]> {
        return await Api.get<SummaryCardType[]>("/summary-cards")
    }

    public static async fetchAvailableCharts(): Promise<DashboardChartType[]> {
        return await Api.get<DashboardChartType[]>("/charts")
    }

    public static async updateCustomProviders({ providerId, operations }: { providerId: number; operations: jsonpatch.Operation[] }) {
        await Api.patch(`/nomenclature/providers/${providerId}`, operations)
    }

    public static async fetchUserRentingPeriods(): Promise<TenantRentingPeriod[]> {
        const response = await Api.get<TenantRentingPeriodDto[]>("/property-tenant")

        return response.map((r) => ({
            ...r,
            from: fromIsoToDateTime(r.from),
            to: fromIsoToDateTime(r.to),
        }))
    }

    public static async fetchUserContractTemplates({ queryKey }: QueryFunctionContext): Promise<UserContractTemplate[]> {
        const [_, data] = queryKey
        const { isArchived } = data as { isArchived: boolean }

        const response = await Api.get<UserContractTemplateDto[]>(`/templates?$filter=isArchived eq ${isArchived}`)

        return response.map((r) => ({
            ...r,
            createdAt: fromIsoToDateTime(r.createdAt),
        }))
    }

    public static async getNotesUrl(propertyId: number, page: number, pageSize: number, sortingColumns: GridSortModel, context: AppContext) {
        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        let url: string
        if (context === AppContext.Owner) {
            url = `/notes?$filter=propertyId eq ${propertyId}&${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        } else {
            url = `/notes?propertyId=${propertyId}&${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        }
        return Api.get<PaginatedData<NotesDto>>(url)
    }

    public static async fetchNotificationsTemplates(): Promise<PaginatedData<NotificationTemplate>> {
        const response = await Api.get<PaginatedData<NotificationTemplateDto>>("/payment-notifications")

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
            })),
            count: response.count,
        }
    }

    public static async fetchGeneralDocuments(): Promise<PaginatedData<GeneralDocument>> {
        const response = await Api.get<PaginatedData<GeneralDocumentDto>>("/general-documents")

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchBankStatementDetails(id: number): Promise<BankStatementDetails> {
        const response = await Api.get<BankStatementDetailsDto>(`/statements/${id}`)

        return {
            ...response,
            periodStart: fromIsoToDateTime(response.periodStart),
            periodEnd: fromIsoToDateTime(response.periodEnd),
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
            transactions: response.transactions.map((t) => ({
                ...t,
                date: fromIsoToDateTime(t.date),
            })),
        }
    }

    public static async fetchBankStatementTransactionDetails(statementId: number, id: number): Promise<BankStatementTransactionDetails> {
        const response = await Api.get<BankStatementTransactionDetailsDto>(`/statements/${statementId}/transactions/${id}`)

        return {
            ...response,
            date: fromIsoToDateTime(response.date),
            associatedExpenses: response.associatedExpenses.map((dto) => ({ ...dto, date: fromIsoToDateTime(dto.date) })),
        }
    }

    public static async fetchBankStatementTransactionPossibleExpenses(statementId: number, id: number): Promise<PropertyExpense[]> {
        const response = await Api.get<PropertyExpenseDto[]>(`/statements/${statementId}/transactions/${id}/expenses`)

        return response.map((e) => ({
            ...e,
            date: fromIsoToDateTime(e.date),
            dueDate: fromIsoToDateTime(e.dueDate),
            dateAutomaticallySentToTenant: e.dateAutomaticallySentToTenant != null ? fromIsoToDateTime(e.dateAutomaticallySentToTenant) : undefined,
            providerInvoiceDate: e.providerInvoiceDate != null ? fromIsoToDateTime(e.providerInvoiceDate) : undefined,
            propertyIncomes: e.propertyIncomes.map((i) => ({ ...i, date: fromIsoToDateTime(i.date) })),
        }))
    }

    public static async fetchBankStatements({ queryKey }: QueryFunctionContext): Promise<PaginatedData<BankStatement>> {
        const [_, data] = queryKey

        const { page, pageSize, sortingColumns, filterModel } = data as {
            page: number
            pageSize: number
            sortingColumns: GridSortModel
            filterModel: GridFilterModel
        }

        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/statements?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`

        if ((filterModel?.items.length ?? 0) > 0) {
            url = `${url}&$filter=${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        const response = await Api.get<PaginatedData<BankStatementDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                periodStart: fromIsoToDateTime(dto.periodStart),
                periodEnd: fromIsoToDateTime(dto.periodEnd),
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteBankStatements({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<BankStatement>> {
        const [_, data] = queryKey
        const { sortingColumns, filterModel } = data as {
            sortingColumns: GridSortModel
            filterModel?: GridFilterModel
        }

        const lastItemIndex = pageParam as number | undefined

        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        let url = `/statements?&${orderBy} desc&$skip=${lastItemIndex ?? 0}&$top=8`

        if ((filterModel?.items ?? []).length > 0) {
            url = `${url} and  ${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        const response = await Api.get<PaginatedData<BankStatementDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                periodStart: fromIsoToDateTime(dto.periodStart),
                periodEnd: fromIsoToDateTime(dto.periodEnd),
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchGeneralRentingPeriodNotificationPreview(rentingPeriodId: number, isDummy: boolean) {
        return await Api.getBlob(`/tenants/${rentingPeriodId}/payment-notification/preview?isDummy=${isDummy}`)
    }

    public static async fetchNotes({ queryKey }: QueryFunctionContext): Promise<PaginatedData<Notes>> {
        const [_, data] = queryKey
        const { propertyId, page, pageSize, sortingColumns, context } = data as {
            propertyId: number
            page: number
            pageSize: number
            sortingColumns: GridSortModel
            context: AppContext
        }

        const response = await Api.getNotesUrl(propertyId, page, pageSize, sortingColumns, context)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                addedAt: fromIsoToDateTime(dto.addedAt),
                updatedAt: fromIsoToDateTime(dto.updatedAt),
            })),
            count: response.count,
        }
    }

    public static async getInfiniteNotesUrl(propertyId: number, sortingColumns: GridSortModel, context: AppContext, pageParam: number | undefined) {
        const lastItemIndex = pageParam as number | undefined

        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url: string
        if (context === AppContext.Owner) {
            url = `/notes?$filter=propertyId eq ${propertyId}&${orderBy} desc&$skip=${lastItemIndex ?? 0}&$top=8`
        } else {
            url = `/notes?propertyId=${propertyId}&${orderBy} desc&$skip=${lastItemIndex ?? 0}&$top=8`
        }
        return Api.get<PaginatedData<NotesDto>>(url)
    }

    public static async fetchInfiniteNotes({ pageParam, queryKey }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<Notes>> {
        const [_, data] = queryKey
        const { propertyId, sortingColumns, context } = data as {
            propertyId: number
            sortingColumns: GridSortModel
            context: AppContext
        }

        const response = await Api.getInfiniteNotesUrl(propertyId, sortingColumns, context, pageParam)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                addedAt: fromIsoToDateTime(dto.addedAt),
                updatedAt: fromIsoToDateTime(dto.updatedAt),
            })),
            count: response.count,
        }
    }

    public static async updateNotes({ noteId, operations }: { noteId: number; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<NotesDto>(`/notes/${noteId}`, operations)
        const parsed: Notes = {
            ...response,
            addedAt: fromIsoToDateTime(response.addedAt),
            updatedAt: fromIsoToDateTime(response.updatedAt),
        }
        return parsed
    }

    public static async fetchBillingDetails(): Promise<BillingDetails | null> {
        const response = await Api.get<BillingDetailsDto | string>("/user/billing")
        if (response == null || typeof response === "string") return null

        return {
            ...response,
            expiry: fromIsoToDateTime(response.expiry),
        }
    }

    public static async fetchCustomerPortalURL(): Promise<string> {
        return Api.get<string>("/user/customer-portal")
    }

    public static async fetchUserTenantRequests() {
        const response = await Api.get<TenantRequestDto[]>("/user/tenant-requests")
        return response.map((r) => ({
            ...r,
            invitedDate: fromIsoToDateTime(r.invitedDate),
            moveInDate: fromIsoToDateTime(r.moveInDate),
            moveOutDate: fromIsoToDateTime(r.moveOutDate),
        }))
    }

    public static async fetchManagerRequests() {
        const response = await Api.get<ManagerRequestDto[]>("/user/owner-requests")
        return response.map((r) => ({
            ...r,
            invitedDate: fromIsoToDateTime(r.invitedDate),
        }))
    }

    public static async fetchUserInsurance({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { id, page, pageSize, sortingColumns } = data as { id?: number; page: number; pageSize: number; sortingColumns: GridSortModel }
        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        let url = `/insurance?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        if (id) {
            url = `${url}&$filter=propertyId eq ${id}`
        }
        const response = await Api.get<PaginatedData<PropertyInsuranceDto>>(url)
        const parsed: PaginatedData<PropertyInsurance> = {
            items: response.items.map((dto) => ({
                ...dto,
                startDate: fromIsoToDateTime(dto.startDate),
                endDate: fromIsoToDateTime(dto.endDate),
            })),
            count: response.count,
        }
        return parsed
    }

    public static async fetchUserGroups() {
        const response = await Api.get<UserGroupDto[]>("/groups")

        return response.map((dto) => ({
            ...dto,
            dashboardSharingSettings: dto.dashboardSharingSettings.map((dss) => ({
                ...dss,
                createdAt: fromIsoToDateTime(dss.createdAt),
                expirationDate: dss.expirationDate ? fromIsoToDateTime(dss.expirationDate) : undefined,
                lastModifiedAt: fromIsoToDateTime(dss.lastModifiedAt),
            })),
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchDashboardConfig(groupId: number, dashboardConfigId: number) {
        const response = await Api.get<DashboardConfigDetails>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigId}`)

        return response
    }

    public static async fetchPropertyExpenseProviders({ queryKey }: QueryFunctionContext): Promise<ConfiguredExpenseProvider[]> {
        const [_, data] = queryKey
        const { propertyId } = data as { propertyId: string }
        const response = await Api.get<ConfiguredExpenseProviderDto[]>(`/property/${propertyId}/configured-providers`)

        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastExpenseCreationDate: fromIsoToDateTime(dto.lastExpenseCreationDate),
        }))
    }

    public static async fetchRentingPeriodJournals({ queryKey }: QueryFunctionContext): Promise<PaginatedData<Journal>> {
        const [_, data] = queryKey
        const { rentingPeriodId, page, pageSize, sortingColumns, filterModel } = data as {
            rentingPeriodId: number
            page: number
            pageSize: number
            sortingColumns: GridSortModel
            filterModel?: GridFilterModel
        }

        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/diary?${orderBy}$skip=${page * pageSize}&$top=${pageSize}&$filter=rentingPeriodId eq ${rentingPeriodId}`
        if ((filterModel?.items.length ?? 0) > 0) {
            url +=
                " and " +
                filterModel?.items
                    .map((item) => item.field + " " + Api.muiFilterOperatorToOdata(item.operator) + " " + item.value)
                    .join(" " + filterModel?.logicOperator + " ")
        }

        const response = await Api.get<PaginatedData<JournalDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteJournals({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<Journal>> {
        const [_, data] = queryKey
        const { rentingPeriodId, sortingColumns, filterModel } = data as {
            rentingPeriodId: number
            sortingColumns: GridSortModel
            filterModel?: GridFilterModel
        }

        const lastItemIndex = pageParam as number | undefined

        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        let url = `/diary?&${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8&$filter=rentingPeriodId eq ${rentingPeriodId}`

        if ((filterModel?.items ?? []).length > 0) {
            url = `${url} and  ${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        const response = await Api.get<PaginatedData<JournalDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteInvoicingClients({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<InvoicingConfigurationClient>> {
        const [_, data] = queryKey
        const { sortingColumns, filters } = data as {
            sortingColumns: GridSortModel
            filters?: GridFilterModel
        }

        const lastItemIndex = pageParam as number | undefined

        let filterBy = ""

        if ((filters?.items.length ?? 0) > 0) {
            filterBy = `$filter=${filters?.items
                .map((i) => {
                    if (i.operator === "startswith") {
                        return `startswith(${i.field}, '${i.value}')`
                    } else if (i.operator === "notstartswith") {
                        return `not startswith(${i.field}, '${i.value}')`
                    }
                    return `${i.field} ${Api.muiFilterOperatorToOdata(i.operator)} '${i.value}'`
                })
                .join(" " + filters.logicOperator + " ")}`
        } else {
            filterBy = ""
        }

        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        const url = `/nomenclature/invoicing-configuration/clients/odata?&${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8`

        const queryParams = [url, filterBy].filter(Boolean).join("&")
        const response = await Api.get<PaginatedData<InvoicingConfigurationClientDto>>(queryParams)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async addJournal(data: AddJournalRequest) {
        const formData = new FormData()

        formData.set("propertyId", data.propertyId.toString())
        formData.set("rentingPeriodId", data.rentingPeriodId.toString())
        formData.set("title", data.title.toString())
        formData.set("description", data.description.toString())

        if (data.files) {
            data.files.forEach((f) => formData.append("files", f))
        }

        const response = await Api.post<JournalDto>("/diary", formData)
        const parsed: Journal = {
            ...response,
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
        }
        return parsed
    }

    public static async updateJournal({ journalId, operations }: { journalId: number; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<JournalDto>(`/diary/${journalId}`, operations)
        const parsed: Journal = {
            ...response,
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
        }
        return parsed
    }

    public static async deleteJournal({ journalId }: { journalId: number }) {
        return Api.delete<never>(`/diary/${journalId}`)
    }

    public static async deleteAssociatedExpenseToTransaction({
        statementId,
        transactionId,
        expenseId,
    }: {
        statementId: number
        transactionId: number
        expenseId: number
    }) {
        return Api.delete<never>(`/statements/${statementId}/transactions/${transactionId}/expenses/${expenseId}`)
    }

    public static async processBankStatement({ statementId }: { statementId: number }) {
        return Api.put<never>(`/statements/${statementId}/process`, {})
    }

    public static async fetchAvailableExpenseProviders(): Promise<ExpenseProvider[]> {
        return Api.get<ExpenseProvider[]>("/expense-providers")
    }

    public static async fetchAvailableMeterProviders(): Promise<MeterProvider[]> {
        return Api.get<MeterProvider[]>("/meter-providers")
    }

    public static async fetchInfiniteUserInsurance({ pageParam, queryKey }: QueryFunctionContext & { pageParam: number }) {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { id } = data as { id?: number }
        let url = `/insurance?$orderby=endDate desc&$skip=${lastItemIndex ?? 0}&$top=8`
        if (id) {
            url = `${url}&$filter=propertyId eq ${id}`
        }
        const response = await Api.get<PaginatedData<PropertyInsuranceDto>>(url)
        const parsed: PaginatedData<PropertyInsurance> = {
            items: response.items.map((dto) => ({
                ...dto,
                startDate: fromIsoToDateTime(dto.startDate),
                endDate: fromIsoToDateTime(dto.endDate),
            })),
            count: response.count,
        }
        return parsed
    }

    public static async fetchPropertyDetails({ queryKey }: QueryFunctionContext): Promise<PropertyData> {
        const [_, data] = queryKey
        const { id } = data as { id: number }

        const response = await Api.get<PropertyDataDto>(`/property/${id}`)
        return {
            ...response,
            invitedUsers: response.invitedUsers.map((invitedUser) => ({
                ...invitedUser,
                lastTry: fromIsoToDateTime(invitedUser.lastTry),
            })),
            users: response.users.map((user) => ({
                ...user,
                date: fromIsoToDateTime(user.date),
            })),
        }
    }

    public static async getOwnerExpenses(
        pageSize: number,
        page: number,
        sortingColumns: GridSortModel,
        fetchPending: boolean,
        properties: number[],
        rentingPeriodId?: number,
        filterModel?: GridFilterModel,
    ) {
        let orderBy = ""

        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)

        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        let url = `/expenses?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        if (properties.length > 0 && rentingPeriodId === undefined) {
            url = `${url}&$filter=propertyId in (${properties.join(",")}) and ${fetchPending ? "status eq 'Pending'" : "status eq 'Accepted'"}`
            if ((filterModel?.items.length ?? 0) > 0) {
                url +=
                    " and " +
                    filterModel?.items
                        .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                        .join(" " + filterModel.logicOperator + " ")
            }
        } else if (rentingPeriodId) {
            url = `${url}&$filter=rentingPeriodId eq ${rentingPeriodId} and ${fetchPending ? "status eq 'Pending'" : "status eq 'Accepted'"}`
            if ((filterModel?.items.length ?? 0) > 0) {
                url +=
                    " and " +
                    filterModel?.items
                        .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                        .join(" " + filterModel.logicOperator + " ")
            }
        } else if ((filterModel?.items.length ?? 0) === 0) {
            url = `${url}&$filter=${fetchPending ? "status eq 'Pending'" : "status eq 'Accepted'"}`
        } else if ((filterModel?.items.length ?? 0) > 0) {
            url = `${url}&$filter=status ne 'Pending' and ${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        return Api.get<PaginatedData<PropertyExpenseDto>>(url)
    }

    private static muiFilterOperatorToOdata(operator: string) {
        switch (operator) {
            case ">=":
                return "gte"
            case ">":
                return "gt"
            case "<":
                return "lt"
            case "equals":
            case "=":
                return "eq"
            default:
                return operator
        }
    }

    public static async getTenantExpenses(
        pageSize: number,
        page: number,
        sortingColumns: GridSortModel,
        propertyId?: number,
        filterModel?: GridFilterModel,
    ) {
        let orderBy = ""
        let filterBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        if ((filterModel?.items.length ?? 0) > 0) {
            filterBy = `$filter=${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        } else filterBy = ""
        return Api.get<PaginatedData<PropertyExpenseDto>>(
            `/expenses?propertyId=${propertyId}&${orderBy}$skip=${page * pageSize}&$top=${pageSize}&${filterBy}`,
        )
    }

    public static async fetchExpenses({ queryKey }: QueryFunctionContext): Promise<PaginatedData<PropertyExpense>> {
        const [_, data] = queryKey
        const { properties, page, pageSize, context, sortingColumns, rentingPeriodId, pending, filterModel } = data as {
            properties: number[]
            page: number
            pageSize: number
            context: AppContext
            sortingColumns: GridSortModel
            rentingPeriodId?: number
            pending: boolean
            filterModel: GridFilterModel
        }

        let response: PaginatedData<PropertyExpenseDto> | null
        if (context === AppContext.Owner) {
            response = await Api.getOwnerExpenses(pageSize, page, sortingColumns, pending, properties, rentingPeriodId, filterModel)
        } else {
            response = await Api.getTenantExpenses(pageSize, page, sortingColumns, properties[0], filterModel)
        }

        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
                dueDate: fromIsoToDateTime(dto.dueDate),
                dateAutomaticallySentToTenant: dto.dateAutomaticallySentToTenant ? fromIsoToDateTime(dto.dateAutomaticallySentToTenant) : undefined,
                providerInvoiceDate: dto.providerInvoiceDate ? fromIsoToDateTime(dto.providerInvoiceDate) : undefined,
                propertyIncomes: dto.propertyIncomes.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
            })),
            count: response.count,
        }
    }

    public static async fetchExpenseIncomes({ queryKey }: QueryFunctionContext): Promise<Array<PropertyIncome>> {
        const [_, data] = queryKey
        const { incomeIds, propertyId } = data as {
            incomeIds: number[]
            propertyId?: number
        }

        let url = `/income?$filter=${incomeIds.map((i) => " id eq " + i).join("or")}`
        if (propertyId) {
            url += `&propertyId=${propertyId}`
        }

        const response = await Api.get<PaginatedData<PropertyIncomeDto>>(url)
        return response.items.map((dto) => ({
            ...dto,
            date: fromIsoToDateTime(dto.date),
        }))
    }

    public static async fetchExpenseLateFeesDetails({ queryKey }: QueryFunctionContext): Promise<ExpenseLateFeesResponse> {
        const [_, data] = queryKey
        const { expenseId } = data as {
            expenseId: number
        }

        const response = await Api.get<ExpenseLateFeesDto>(`/expenses/${expenseId}/late-fees`)
        return {
            ...response,
            lateFees: response.lateFees.map((lf) => ({ ...lf, endDate: fromIsoToDateTime(lf.endDate), startDate: fromIsoToDateTime(lf.startDate) })),
            alreadyGeneratedExpenses: response.alreadyGeneratedExpenses.map((lf) => ({ ...lf, date: fromIsoToDateTime(lf.date) })),
        }
    }

    public static async fetchIncomeExpenses({ queryKey }: QueryFunctionContext): Promise<Array<PropertyExpense>> {
        const [_, data] = queryKey
        const { expenseIds } = data as {
            expenseIds: number[]
        }

        const url = `/expenses?$filter=${expenseIds.map((i) => " id eq " + i).join("or")}`
        const response = await Api.get<PaginatedData<PropertyExpenseDto>>(url)
        return response.items.map((dto) => ({
            ...dto,
            date: fromIsoToDateTime(dto.date),
            dueDate: fromIsoToDateTime(dto.dueDate),
            dateAutomaticallySentToTenant: dto.dateAutomaticallySentToTenant ? fromIsoToDateTime(dto.dateAutomaticallySentToTenant) : undefined,
            providerInvoiceDate: dto.providerInvoiceDate ? fromIsoToDateTime(dto.providerInvoiceDate) : undefined,
            propertyIncomes: dto.propertyIncomes.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
        }))
    }

    public static async fetchRedirectPaymentPage(expenseId: number) {
        return Api.post<never>(`/expenses/${expenseId}/pay`, {})
    }

    public static async fetchUserInvoices(): Promise<UserInvoice[] | null> {
        // const response = await Api.get<UserInvoicesDto[] | string>("")
        // if (response == null) return null

        return [
            { id: 1, name: "Factura 1", date: DateTime.now(), amount: "300 Ron" },
            { id: 2, name: "Factura 2", date: DateTime.now(), amount: "400 Ron" },
            { id: 3, name: "Factura 3", date: DateTime.now(), amount: "600 Ron" },
            { id: 4, name: "Factura 4", date: DateTime.now(), amount: "400 Ron" },
            { id: 5, name: "Factura 5", date: DateTime.now(), amount: "900 Ron" },
            { id: 6, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 7, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 8, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 9, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 10, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 16, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 26, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 36, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 46, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 56, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 66, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 76, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 86, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 96, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 116, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
            { id: 1126, name: "Factura 6", date: DateTime.now(), amount: "700 Ron" },
        ]
    }

    public static async fetchRecurringExpenses({ queryKey }: QueryFunctionContext): Promise<PaginatedData<RecurringExpense>> {
        const [_, data] = queryKey
        const { page, pageSize, sortingColumns, propertyId } = data as {
            page: number
            pageSize: number
            sortingColumns: GridSortModel
            propertyId?: number
        }

        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        let url = `/expenses/recurring?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`

        if (propertyId) {
            url = `${url}&$filter=propertyId eq ${propertyId}`
        } else {
            url = `${url}`
        }

        const response = await Api.get<PaginatedData<RecurringExpenseDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                startDate: fromIsoToDateTime(dto.startDate),
                createdAt: fromIsoToDateTime(dto.createdAt),
                modifiedAt: fromIsoToDateTime(dto.modifiedAt),
                expiresAt: dto.expiresAt ? fromIsoToDateTime(dto.expiresAt) : undefined,
            })),
            count: response.count,
        }
    }

    public static async getExpense(expenseId: number): Promise<PropertyExpense | undefined> {
        const response = await Api.get<PaginatedData<PropertyExpenseDto>>(`/expenses?$filter=id eq ${expenseId}`)
        return response.items
            .map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
                dueDate: fromIsoToDateTime(dto.dueDate),
                dateAutomaticallySentToTenant: dto.dateAutomaticallySentToTenant ? fromIsoToDateTime(dto.dateAutomaticallySentToTenant) : undefined,
                providerInvoiceDate: dto.providerInvoiceDate ? fromIsoToDateTime(dto.providerInvoiceDate) : undefined,
                propertyIncomes: dto.propertyIncomes.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
            }))
            .shift()
    }

    public static async getPendingExpense({ queryKey }: QueryFunctionContext): Promise<PropertyExpense> {
        const [_, data] = queryKey
        const { expenseId } = data as {
            expenseId: number
        }
        const response = await Api.get<PropertyExpenseDto>(`/expenses/pending/${expenseId}`)

        return {
            ...response,
            date: fromIsoToDateTime(response.date),
            dueDate: fromIsoToDateTime(response.dueDate),
            dateAutomaticallySentToTenant: response.dateAutomaticallySentToTenant
                ? fromIsoToDateTime(response.dateAutomaticallySentToTenant)
                : undefined,
            providerInvoiceDate: response.providerInvoiceDate ? fromIsoToDateTime(response.providerInvoiceDate) : undefined,
            propertyIncomes: response.propertyIncomes?.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
        }
    }

    public static async getExpensesSummaryReport({
        queryKey,
    }: QueryFunctionContext): Promise<Record<ExpensesFilteredOptions, { count: number; sum: number }>> {
        const [_, data] = queryKey
        const { properties, currentRentingPeriodId } = data as {
            properties: number[] | undefined
            currentRentingPeriodId?: number
        }

        const propertyIdsQueryParam = properties?.length !== 0 ? (properties ?? []).map((propertyId) => `properties=${propertyId}`).join("&") : ""
        const rentingPeriodIdQueryParam = currentRentingPeriodId != null ? `rentingPeriodId=${currentRentingPeriodId}` : ""

        return await Api.get<Record<ExpensesFilteredOptions, { count: number; sum: number }>>(
            `/expenses/expenses-report?${propertyIdsQueryParam}&${rentingPeriodIdQueryParam}`,
        )
    }

    public static async getIncomesSummaryReport({
        queryKey,
    }: QueryFunctionContext): Promise<Record<IncomesFilteredOptions, { count: number; sum: number }>> {
        const [_, data] = queryKey
        const { propertiesIds, rentingPeriodId } = data as {
            propertiesIds: number[] | undefined
            rentingPeriodId?: number
        }

        const propertyIdsQueryParam =
            propertiesIds?.length !== 0 ? (propertiesIds ?? []).map((propertyId) => `properties=${propertyId}`).join("&") : ""
        const rentingPeriodIdQueryParam = rentingPeriodId != null ? `rentingPeriodId=${rentingPeriodId}` : ""

        return await Api.get<Record<IncomesFilteredOptions, { count: number; sum: number }>>(
            `/income/income-report?${propertyIdsQueryParam}&${rentingPeriodIdQueryParam}`,
        )
    }

    public static async getTenantExpense(expenseId: number, propertyId: number): Promise<PropertyExpense | undefined> {
        const response = await Api.get<PaginatedData<PropertyExpenseDto>>(`/expenses?propertyId=${propertyId}&$filter=id eq ${expenseId}`)
        return response.items
            .map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
                dueDate: fromIsoToDateTime(dto.dueDate),
                dateAutomaticallySentToTenant: dto.dateAutomaticallySentToTenant ? fromIsoToDateTime(dto.dateAutomaticallySentToTenant) : undefined,
                providerInvoiceDate: dto.providerInvoiceDate ? fromIsoToDateTime(dto.providerInvoiceDate) : undefined,
                propertyIncomes: dto.propertyIncomes.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
            }))
            .shift()
    }

    public static async getIncome(incomeId: number): Promise<PropertyIncome | undefined> {
        const response = await Api.get<PaginatedData<PropertyIncomeDto>>(`/income?$filter=id eq ${incomeId}`)
        return response.items
            .map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
            }))
            .shift()
    }

    public static async getSingleActiveUser(activeUserId: string): Promise<ActiveUser | undefined> {
        const response = await Api.get<PaginatedData<ActiveUserDto>>(`/user/registered-users?$filter=id eq '${activeUserId}'`)
        try {
            return response.items
                .map((dto) => ({
                    ...dto,
                    createdAt: DateTime.fromISO(dto.createdAt),
                    lastActive: DateTime.fromISO(dto.lastActive),
                }))
                .shift()
        } catch {
            return undefined
        }
    }

    public static async updateActiveUser({ userId, accountType }: { userId: string; accountType: AccountType }) {
        await Api.post(`/user/registered-users?userId=${userId}&accountType=${accountType}`, {})
    }
    public static async getInsurance(insuranceId: number): Promise<PropertyInsurance | undefined> {
        const response = await Api.get<PaginatedData<PropertyInsuranceDto>>(`/insurance?$filter=id eq ${insuranceId}`)
        return response.items
            .map((dto) => ({
                ...dto,
                endDate: fromIsoToDateTime(dto.endDate),
                startDate: fromIsoToDateTime(dto.startDate),
            }))
            .shift()
    }

    public static async getTenantIncome(incomeId: number, propertyId: number): Promise<PropertyIncome | undefined> {
        const response = await Api.get<PaginatedData<PropertyIncomeDto>>(`/income?propertyId=${propertyId}&$filter=id eq ${incomeId}`)
        return response.items
            .map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
            }))
            .shift()
    }

    public static async fetchEvents(_: QueryFunctionContext): Promise<ICalendarEvent[]> {
        return Api.get("/events")
    }

    public static async search(query: string): Promise<SearchResult[]> {
        return Api.get(`/user/search?query=${query}`)
    }

    public static async getOwnerInfiniteExpenses(
        fetchPending: boolean,
        properties: number[],
        sortingModel?: GridSortModel,
        lastItemIndex?: number,
        rentingPeriodId?: number,
        filterModel?: GridFilterModel,
    ) {
        let orderBy = ""
        if ((sortingModel?.length ?? 0) > 0) orderBy = `$orderby=${sortingModel?.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = "$orderby=date desc&"

        let url = `/expenses?${orderBy}$skip=${lastItemIndex ?? 0}&$top=8`
        if (properties.length > 0 && rentingPeriodId === undefined) {
            url = `${url}&$filter=propertyId in (${properties.join(",")})and ${fetchPending ? "status eq 'Pending'" : "status eq 'Accepted'"}`
        } else if (rentingPeriodId) {
            url = `${url}&$filter=rentingPeriodId eq ${rentingPeriodId} and ${fetchPending ? "status eq 'Pending'" : "status eq 'Accepted'"}`
        } else {
            url = `${url}&$filter=${fetchPending ? "status eq 'Pending'" : "status eq 'Accepted'"}`
        }

        if ((filterModel?.items ?? []).length > 0) {
            url = `${url} and  ${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        return Api.get<PaginatedData<PropertyExpenseDto>>(url)
    }

    public static async getOwnerInfiniteRecurringExpenses(propertyId?: number, sortingModel?: GridSortModel, lastItemIndex?: number) {
        let orderBy = ""
        if ((sortingModel?.length ?? 0) > 0) orderBy = `$orderby=${sortingModel?.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = "$orderby=date desc&"
        let url = `/expenses/recurring?${orderBy}$skip=${lastItemIndex ?? 0}&$top=8`

        if (propertyId) {
            url = `${url}&$filter=propertyId eq ${propertyId}`
        }
        return Api.get<PaginatedData<RecurringExpenseDto>>(url)
    }

    public static async getTenantInfiniteExpenses(
        sortingColumns?: GridSortModel,
        lastItemIndex?: number,
        propertyId?: number,
        filterModel?: GridFilterModel,
    ) {
        let orderBy = ""
        if ((sortingColumns?.length ?? 0) > 0) orderBy = `$orderby=${sortingColumns?.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/expenses?propertyId=${propertyId}&${orderBy}$skip=${lastItemIndex ?? 0}&$top=8`

        if ((filterModel?.items ?? []).length > 0) {
            url = `${url}&$filter=${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        return Api.get<PaginatedData<PropertyExpenseDto>>(url)
    }

    public static async fetchInfiniteExpenses({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<PropertyExpense>> {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { properties, sortingModel, rentingPeriodId, pending, filterModel } = data as {
            properties: number[]
            sortingModel?: GridSortModel
            rentingPeriodId?: number
            pending: boolean
            filterModel?: GridFilterModel
        }
        let response: PaginatedData<PropertyExpenseDto>

        if (Api.CONTEXT === AppContext.Owner) {
            response = await Api.getOwnerInfiniteExpenses(pending, properties, sortingModel, lastItemIndex, rentingPeriodId, filterModel)
        } else {
            response = await Api.getTenantInfiniteExpenses(sortingModel, lastItemIndex, properties[0], filterModel)
        }

        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
                dueDate: fromIsoToDateTime(dto.dueDate),
                dateAutomaticallySentToTenant: dto.dateAutomaticallySentToTenant ? fromIsoToDateTime(dto.dateAutomaticallySentToTenant) : undefined,
                providerInvoiceDate: dto.providerInvoiceDate ? fromIsoToDateTime(dto.providerInvoiceDate) : undefined,
                propertyIncomes: dto.propertyIncomes.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteRecurringExpenses({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<RecurringExpense>> {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { propertyId, sortingModel } = data as {
            sortingModel?: GridSortModel
            propertyId?: number
        }
        const response: PaginatedData<RecurringExpenseDto> = await Api.getOwnerInfiniteRecurringExpenses(propertyId, sortingModel, lastItemIndex)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                startDate: fromIsoToDateTime(dto.startDate),
                createdAt: fromIsoToDateTime(dto.createdAt),
                modifiedAt: fromIsoToDateTime(dto.modifiedAt),
                expiresAt: dto.expiresAt ? fromIsoToDateTime(dto.expiresAt) : undefined,
            })),
            count: response.count,
        }
    }

    public static fetchSummaryCards({ queryKey }: QueryFunctionContext): Promise<SummaryCardDto> {
        const [_, data] = queryKey
        const { cardId, propertyId, groupId, id, idp } = data as { cardId: number; propertyId?: number; groupId?: number; id?: string; idp?: string }
        if (id != null && idp != null) {
            return Api.get<SummaryCardDto>(`/dashboard/p/summary-cards/${cardId}?id=${id}&idp=${idp}`)
        }
        return Api.get<SummaryCardDto>(
            `/summary-cards/${cardId}?${propertyId != null ? `propertyId=${propertyId}` : ""}${groupId != null ? `&groupId=${groupId}` : ""}`,
        )
    }

    public static async fetchProvidersWithoutExpenseThisMonth(): Promise<ProviderWithoutExpenseThisMonth[]> {
        const response = await Api.get<ProviderWithoutExpenseThisMonthDto[]>("/expense-providers/without-expenses-this-month")
        return response.map((r) => ({ ...r, lastExpenseDate: r.lastExpenseDate != null ? fromIsoToDateTime(r.lastExpenseDate) : undefined }))
    }

    public static async fetchCharts({ queryKey }: QueryFunctionContext): Promise<DashboardChart> {
        const [_, data] = queryKey
        const { chartId, propertyId, groupId, id, idp } = data as { chartId: number; propertyId: number; groupId: number; id?: string; idp?: string }

        const response =
            id != null && idp != null
                ? await Api.get<DashboardChartDto>(`/dashboard/p/charts/${chartId}?id=${id}&idp=${idp}`)
                : await Api.get<DashboardChartDto>(
                      `/charts/${chartId}?${propertyId != null ? `propertyId=${propertyId}` : ""}${groupId != null ? `groupId=${groupId}` : ""}`,
                  )
        return {
            ...response,
            values: response.values.map((v) => ({ ...v, dateTime: fromIsoToDateTime(v.dateTime) })),
        }
    }

    public static async fetchTables({ queryKey }: QueryFunctionContext): Promise<DashboardMetersTable> {
        const [_, data] = queryKey
        const { tableId, propertyId } = data as { tableId: number; propertyId?: number }
        const response = await Api.get<DashboardMetersTableDto>(`/tables/${tableId}?${propertyId != null ? `propertyId=${propertyId}` : ""}`)

        return {
            ...response,
            values: response.values.map((v) => ({
                ...v,
                id: Number(v.id),
                readingPeriod: v.readingPeriod ? JSON.parse(v.readingPeriod) : "",
                averageReadingDifference: Number(v.averageReadingDifference),
                readThisMonth: v.readThisMonth === "True",
                currentValue: Number(v.currentValue),
                previousValue: Number(v.previousValue),
                unitId: Number(v.unitId),
                propertyId: Number(v.propertyId),
            })),
        }
    }

    public static async getUsersUsingPromoCode(): Promise<UsersWithPromoCode[]> {
        const response = await Api.get<UsersWithPromoCodeDto[]>("/user/promotional-code")
        return response.map((dto, index) => ({
            ...dto,
            promotionalCodeUsedAt: fromIsoToDateTime(dto.promotionalCodeUsedAt),
            id: index,
        }))
    }

    public static async getPromoCodeDetails(data: PromoCodeRequest) {
        return Api.get<PromoCodeDetails[]>(`/user/promotional-code/${data.promoCode}`)
    }

    public static async fetchTenants({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { id } = data as { id?: number }
        let url = "/tenants?$orderBy=moveInDate desc"
        if (id) {
            url = `${url}&$filter=propertyId eq ${id}`
        }
        const response = await Api.get<RentingPeriodDto[]>(url)
        const parsed: RentingPeriod[] = response.map((dto) => ({
            ...dto,
            acceptedDate: dto.acceptedDate ? fromIsoToDateTime(dto.acceptedDate) : undefined,
            invitedDate: dto.invitedDate ? fromIsoToDateTime(dto.invitedDate) : undefined,
            moveInDate: fromIsoToDateTime(dto.moveInDate),
            moveOutDate: fromIsoToDateTime(dto.moveOutDate),
            removeTenantAccessAt: dto.removeTenantAccessAt ? fromIsoToDateTime(dto.removeTenantAccessAt) : undefined,
        }))
        return parsed
    }

    public static async checkPropertyAvailability({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { id, startDate, endDate } = data as { id: number; startDate: string; endDate: string }
        const response = await Api.get<{ isAvailable: boolean }>(
            `/tenants/check-availability?propertyId=${id}&startDate=${startDate}&endDate=${endDate}`,
        )
        return response.isAvailable
    }

    public static async fetchTenantsWithPagination({ queryKey }: QueryFunctionContext): Promise<PaginatedData<RentingPeriod>> {
        const [_, data] = queryKey
        const { id, page, pageSize, sortingColumns, filterModel } = data as {
            id?: number
            page: number
            pageSize: number
            sortingColumns: GridSortModel
            filterModel: GridFilterModel
        }

        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""
        let url = `/tenants/paginated?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`

        if (id) {
            url = `${url}&$filter=propertyId eq ${id}`
            if ((filterModel?.items.length ?? 0) > 0) {
                url +=
                    " and (" +
                    filterModel?.items
                        .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                        .join(" " + filterModel.logicOperator + " ") +
                    ")"
            }
        } else if ((filterModel?.items.length ?? 0) > 0) {
            url = `${url}&$filter=${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        const response = await Api.get<PaginatedData<RentingPeriodDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                acceptedDate: dto.acceptedDate ? fromIsoToDateTime(dto.acceptedDate) : undefined,
                invitedDate: dto.invitedDate ? fromIsoToDateTime(dto.invitedDate) : undefined,
                moveInDate: fromIsoToDateTime(dto.moveInDate),
                moveOutDate: fromIsoToDateTime(dto.moveOutDate),
                removeTenantAccessAt: dto.removeTenantAccessAt ? fromIsoToDateTime(dto.removeTenantAccessAt) : undefined,
            })),
            count: response.count,
        }
    }

    public static async getRentingPeriodsFromAProperty({ queryKey }: QueryFunctionContext): Promise<RentingPeriod[] | undefined> {
        const [_, data] = queryKey
        const { propertyId } = data as { propertyId: number }
        const response = await Api.get<PaginatedData<RentingPeriodDto>>(`/tenants/paginated?$filter=propertyId eq ${propertyId}`)
        return response.items.map((dto) => ({
            ...dto,
            acceptedDate: dto.acceptedDate ? fromIsoToDateTime(dto.acceptedDate) : undefined,
            invitedDate: dto.invitedDate ? fromIsoToDateTime(dto.invitedDate) : undefined,
            moveInDate: fromIsoToDateTime(dto.moveInDate),
            moveOutDate: fromIsoToDateTime(dto.moveOutDate),
            removeTenantAccessAt: dto.removeTenantAccessAt ? fromIsoToDateTime(dto.removeTenantAccessAt) : undefined,
        }))
    }

    public static async fetchInfiniteRentingPeriods({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<RentingPeriod>> {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { id, sortingModel, filterModel } = data as {
            id?: number
            sortingModel?: GridSortModel
            filterModel?: GridFilterModel
        }

        let orderBy = ""
        if ((sortingModel?.length ?? 0) > 0) orderBy = `$orderby=${sortingModel?.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = "$orderby=moveInDate desc&"

        let url = `/tenants/paginated?${orderBy}$skip=${lastItemIndex ?? 0}&$top=8`

        if (id) {
            url = `${url}&$filter=propertyId eq ${id}`
            if ((filterModel?.items.length ?? 0) > 0) {
                url +=
                    " and (" +
                    filterModel?.items
                        .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                        .join(" " + filterModel.logicOperator + " ") +
                    ")"
            }
        } else if ((filterModel?.items.length ?? 0) > 0) {
            url = `${url}&$filter=${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }

        const response = await Api.get<PaginatedData<RentingPeriodDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                acceptedDate: dto.acceptedDate ? fromIsoToDateTime(dto.acceptedDate) : undefined,
                invitedDate: dto.invitedDate ? fromIsoToDateTime(dto.invitedDate) : undefined,
                moveInDate: fromIsoToDateTime(dto.moveInDate),
                moveOutDate: fromIsoToDateTime(dto.moveOutDate),
                removeTenantAccessAt: dto.removeTenantAccessAt ? fromIsoToDateTime(dto.removeTenantAccessAt) : undefined,
            })),
            count: response.count,
        }
    }

    public static async getRentingPeriod(rentingPeriodId: number): Promise<RentingPeriod | undefined> {
        const response = await Api.get<PaginatedData<RentingPeriodDto>>(`/tenants/paginated?$filter=id eq ${rentingPeriodId}`)
        return response.items
            .map((dto) => ({
                ...dto,
                acceptedDate: dto.acceptedDate ? fromIsoToDateTime(dto.acceptedDate) : undefined,
                invitedDate: dto.invitedDate ? fromIsoToDateTime(dto.invitedDate) : undefined,
                moveInDate: fromIsoToDateTime(dto.moveInDate),
                moveOutDate: fromIsoToDateTime(dto.moveOutDate),
                removeTenantAccessAt: dto.removeTenantAccessAt ? fromIsoToDateTime(dto.removeTenantAccessAt) : undefined,
            }))
            .shift()
    }

    public static async fetchSoonToBeExpiredTenants(): Promise<RentingPeriod[]> {
        const url = `/tenants?$filter=moveOutDate lt ${DateTime.now().plus({ day: 30 }).toISO({ includeOffset: false }) + "Z"}`
        const response = await Api.get<RentingPeriodDto[]>(url)

        return response.map((dto) => ({
            ...dto,
            acceptedDate: dto.acceptedDate ? fromIsoToDateTime(dto.acceptedDate) : undefined,
            invitedDate: dto.invitedDate ? fromIsoToDateTime(dto.invitedDate) : undefined,
            moveInDate: fromIsoToDateTime(dto.moveInDate),
            moveOutDate: fromIsoToDateTime(dto.moveOutDate),
            removeTenantAccessAt: dto.removeTenantAccessAt ? fromIsoToDateTime(dto.removeTenantAccessAt) : undefined,
        }))
    }
    public static async fetchRentingPeriodsReport({
        queryKey,
    }: QueryFunctionContext): Promise<Record<RentingPeriodsFilteredOptions, { count: number; sum: number }>> {
        const [_, data] = queryKey
        const { propertiesIds } = data as { propertiesIds: number[] }
        const propertyIdsQueryParam =
            propertiesIds?.length !== 0 ? (propertiesIds ?? []).map((propertyId) => `properties=${propertyId}`).join("&") : ""
        const url = `/tenants/renting-periods-report?${propertyIdsQueryParam}`
        return Api.get<Record<RentingPeriodsFilteredOptions, { count: number; sum: number }>>(url)
    }

    public static async fetchRentingPeriodSummaryData({ queryKey }: QueryFunctionContext): Promise<RentingPeriodSummaryData> {
        const [_, data] = queryKey
        const { rentingPeriodId } = data as { rentingPeriodId: number }

        return Api.get<RentingPeriodSummaryData>(`/tenants/${rentingPeriodId}/data-summary`)
    }

    public static async fetchRentingPeriodDetails({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { rentingPeriodId } = data as { rentingPeriodId?: number }

        const response = await Api.get<RentingPeriodDetailsDto>(`/tenants/${rentingPeriodId}`)

        const parsed: RentingPeriodDetails = {
            ...response,
            invitedDate: fromIsoToDateTime(response.invitedDate),
            moveInDate: fromIsoToDateTime(response.moveInDate),
            moveOutDate: fromIsoToDateTime(response.moveOutDate),
            createdAt: fromIsoToDateTime(response.createdAt),
            removeTenantAccessAt: response.removeTenantAccessAt ? fromIsoToDateTime(response.removeTenantAccessAt) : undefined,
            invitedUsers: response.invitedUsers.map((invitedUser) => ({
                ...invitedUser,
                lastTry: fromIsoToDateTime(invitedUser.lastTry),
            })),
            users: response.users.map((user) => ({
                ...user,
                date: fromIsoToDateTime(user.date),
            })),
        }
        return parsed
    }

    public static async fetchIncome({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { properties, page, pageSize, sortingColumns, rentingPeriodId, filterModel } = data as {
            properties: number[]
            page: number
            pageSize: number
            context: AppContext
            sortingColumns: GridSortModel
            rentingPeriodId?: number
            filterModel: GridFilterModel
        }

        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)

        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/income?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        if (properties.length > 0 && rentingPeriodId === undefined) {
            url = `${url}&$filter=propertyId in (${properties.join(",")})`
            if ((filterModel?.items.length ?? 0) > 0) {
                url +=
                    " and " +
                    filterModel?.items
                        .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                        .join(" " + filterModel.logicOperator + " ")
            }
        } else if (rentingPeriodId) {
            url = `${url}&$filter=rentingPeriodId eq ${rentingPeriodId}`
            if ((filterModel?.items.length ?? 0) > 0) {
                url +=
                    " and " +
                    filterModel?.items
                        .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                        .join(" " + filterModel.logicOperator + " ")
            }
        } else if ((filterModel?.items.length ?? 0) === 0) {
            url = `${url}`
        } else if ((filterModel?.items.length ?? 0) > 0) {
            url = `${url}&$filter=${filterModel?.items
                .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                .join(" " + filterModel.logicOperator + " ")}`
        }
        const response = await Api.get<PaginatedData<PropertyIncomeDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteIncomes({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<PropertyIncome>> {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { properties, sortingModel, rentingPeriodId, filterModel } = data as {
            properties?: number[]
            sortingModel?: GridSortModel
            rentingPeriodId?: number
            filterModel?: GridFilterModel
        }
        let orderBy = ""
        if ((sortingModel?.length ?? 0) > 0) orderBy = `$orderby=${sortingModel?.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = "$orderby=date desc&"

        let url = `/income?${orderBy}`
        if ((properties ?? []).length > 0 && rentingPeriodId === undefined) {
            url =
                `${url}$filter=propertyId in (${properties?.join(",")})` +
                ((filterModel?.items.length ?? 0) > 0
                    ? ` and (${filterModel?.items
                          .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                          .join(" " + filterModel.logicOperator + " ")})`
                    : "")
        } else if (rentingPeriodId) {
            url =
                `${url}&$filter=rentingPeriodId eq ${rentingPeriodId}` +
                ((filterModel?.items.length ?? 0) > 0
                    ? ` and (${filterModel?.items
                          .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                          .join(" " + filterModel.logicOperator + " ")})`
                    : "")
        } else {
            url =
                `${url}$skip=${lastItemIndex ?? 0}&$top=8` +
                ((filterModel?.items.length ?? 0) > 0
                    ? `&$filter=${filterModel?.items
                          .map((i) => i.field + " " + Api.muiFilterOperatorToOdata(i.operator) + " " + i.value)
                          .join(" " + filterModel.logicOperator + " ")}`
                    : "")
        }
        const response = await Api.get<PaginatedData<PropertyIncomeDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
            })),
            count: response.count,
        }
    }

    public static async fetchPayments({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { properties, page, pageSize, sortingColumns } = data as {
            properties?: number[]
            page: number
            pageSize: number
            context: AppContext
            sortingColumns: GridSortModel
        }

        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        const response = await Api.get<PaginatedData<PropertyIncomeDto>>(
            `/income?propertyId=${(properties ?? [])[0]}&${orderBy}$skip=${page * pageSize}&$top=${pageSize}`,
        )
        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
            })),
            count: response.count,
        }
    }

    public static async fetchInfinitePayments({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<PropertyIncome>> {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { properties, sortingModel } = data as { properties?: number[]; sortingModel?: GridSortModel }
        let orderBy = ""
        if ((sortingModel?.length ?? 0) > 0) orderBy = `$orderby=${sortingModel?.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = "$orderby=date desc&"

        const response = await Api.get<PaginatedData<PropertyIncomeDto>>(
            `/income?propertyId=${(properties ?? [])[0]}&${orderBy}$skip=${lastItemIndex ?? 0}&$top=8`,
        )

        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
            })),
            count: response.count,
        }
    }

    public static async fetchGroupRentingPeriods({ queryKey }: QueryFunctionContext): Promise<GroupRentingPeriod> {
        const [_, data] = queryKey

        const { currentGroupId, from, to } = data as {
            currentGroupId: number
            from: string
            to: string
        }

        const response = await Api.get<GroupRentingPeriodDto>(`/groups/${currentGroupId}/renting-periods?start=${from}&end=${to}`)
        const newResponse: GroupRentingPeriod = {}

        Object.keys(response).forEach((k) => {
            newResponse[k] = response[k].map((rp) => ({
                from: fromIsoToDateTime(rp.from),
                to: fromIsoToDateTime(rp.to),
                propertyName: rp.propertyName,
            }))
        })
        return newResponse
    }

    public static async fetchGroupAccess({ queryKey }: QueryFunctionContext): Promise<GroupAccess[]> {
        const [_, data] = queryKey

        const { currentGroupId } = data as {
            currentGroupId: number
        }

        const response = await Api.get<GroupAccessDto[]>(`/groups/${currentGroupId}/access`)
        return response.map((ga) => ({
            ...ga,
            date: fromIsoToDateTime(ga.date),
        }))
    }

    public static async fetchActiveUsers({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey

        const { page, pageSize, sortingColumns } = data as {
            page: number
            pageSize: number
            context: AppContext
            sortingColumns: GridSortModel
        }

        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        const response = await Api.get<PaginatedData<ActiveUserDto>>(`/user/registered-users?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastActive: fromIsoToDateTime(dto.lastActive),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteActiveUsers({ pageParam, queryKey }: QueryFunctionContext & { pageParam: number }) {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { sortingModel } = data as { sortingModel?: GridSortModel }
        let orderBy = ""
        if ((sortingModel?.length ?? 0) > 0) orderBy = `$orderby=${sortingModel?.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = "$orderby=date desc&"

        const response = await Api.get<PaginatedData<ActiveUserDto>>(`/user/registered-users?${orderBy}$skip=${lastItemIndex ?? 0}&$top=8`)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastActive: fromIsoToDateTime(dto.lastActive),
            })),
            count: response.count,
        }
    }

    public static async fetchTenantRevisions({ queryKey }: QueryFunctionContext): Promise<TenantRevision[]> {
        const [_, data] = queryKey
        const { rentingPeriodId } = data as {
            rentingPeriodId: number
        }

        const url = `/maintenance/tenant/${rentingPeriodId}`

        const response = await Api.get<TenantRevisionDto[]>(url)
        return response.map((dto) => ({
            ...dto,
            date: fromIsoToDateTime(dto.date),
            tenantChosenDate: dto.tenantChosenDate != null ? fromIsoToDateTime(dto.tenantChosenDate) : dto.tenantChosenDate,
        }))
    }

    public static async fetchRevisions({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { propertyId, page, pageSize, sortingColumns } = data as {
            propertyId: number
            page: number
            pageSize: number
            sortingColumns: GridSortModel
        }

        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)

        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/maintenance?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        if (propertyId) {
            url = `/maintenance?$filter=propertyId eq ${propertyId}&${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        }
        const response = await Api.get<PaginatedData<PropertyRevisionDto>>(url)
        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
                tenantChosenDate: dto.tenantChosenDate != null ? fromIsoToDateTime(dto.tenantChosenDate) : dto.tenantChosenDate,
            })),
            count: response.count,
        }
    }

    public static async fetchInventorySections({ queryKey }: QueryFunctionContext): Promise<PaginatedData<InventorySection>> {
        const [_, data] = queryKey
        const { propertyId, page, pageSize, sortingColumns } = data as {
            propertyId: number
            page: number
            pageSize: number
            sortingColumns: GridSortModel
        }

        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)

        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/inventory/sections?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        if (propertyId) {
            url = `/inventory/sections?$filter=propertyId eq ${propertyId}&${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        }
        const response = await Api.get<PaginatedData<InventorySectionDto>>(url)
        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteInventorySections({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<InventorySection>> {
        const [_, data] = queryKey
        const { propertyId, sortingColumns } = data as {
            propertyId: number
            sortingColumns: GridSortModel
        }
        const lastItemIndex = pageParam as number | undefined
        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/inventory/sections?${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8`

        if (propertyId) {
            url = `/inventory/sections?$filter=propertyId eq ${propertyId}&${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8`
        }

        const response = await Api.get<PaginatedData<InventorySectionDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteInventorySectionItems({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<InventoryItem>> {
        const [_, data] = queryKey
        const { inventorySectionId, sortingColumns } = data as {
            inventorySectionId: number
            sortingColumns: GridSortModel
        }
        const lastItemIndex = pageParam as number | undefined
        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/inventory/sections/${inventorySectionId}/items?${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8`

        if (inventorySectionId) {
            url = `/inventory/sections/${inventorySectionId}/items?${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8`
        }

        const response = await Api.get<PaginatedData<InventoryItemDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
                purchaseDate: fromIsoToDateTime(dto.purchaseDate),
            })),
            count: response.count,
        }
    }

    public static async fetchInfiniteRevisions({
        pageParam,
        queryKey,
    }: QueryFunctionContext & { pageParam: number }): Promise<PaginatedData<PropertyRevision>> {
        const [_, data] = queryKey
        const { propertyId, sortingColumns } = data as {
            propertyId: number
            sortingColumns: GridSortModel
        }
        const lastItemIndex = pageParam as number | undefined
        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/maintenance?${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8`

        if (propertyId) {
            url = `/maintenance?$filter=propertyId eq ${propertyId}&${orderBy}&$skip=${lastItemIndex ?? 0}&$top=8`
        }

        const response = await Api.get<PaginatedData<PropertyRevisionDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                date: fromIsoToDateTime(dto.date),
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
                tenantChosenDate: dto.tenantChosenDate != null ? fromIsoToDateTime(dto.tenantChosenDate) : dto.tenantChosenDate,
            })),
            count: response.count,
        }
    }

    public static async fetchInventorySectionItems({ queryKey }: QueryFunctionContext): Promise<PaginatedData<InventoryItem>> {
        const [_, data] = queryKey

        const { inventorySectionId, page, pageSize, sortingColumns } = data as {
            inventorySectionId: number
            page: number
            pageSize: number
            sortingColumns: GridSortModel
        }

        let orderBy = ""
        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)

        if (filteredSortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        const url = `/inventory/sections/${inventorySectionId}/items?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`

        const response = await Api.get<PaginatedData<InventoryItemDto>>(url)

        return {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
                purchaseDate: fromIsoToDateTime(dto.purchaseDate),
            })),
            count: response.count,
        }
    }

    public static async fetchRevisionObservations({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { revisionId } = data as {
            revisionId: number
        }

        const response = await Api.get<RevisionObservationDto[]>(`/maintenance/${revisionId}/observations`)

        return response.map((dto) => ({
            ...dto,
            observationDate: fromIsoToDateTime(dto.observationDate),
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchTenantRevisionObservations({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { revisionId, currentRentingPeriodId } = data as {
            revisionId: number
            currentRentingPeriodId: number
        }

        const response = await Api.get<RevisionObservationDto[]>(`/maintenance/${revisionId}/tenant/${currentRentingPeriodId}/observations`)

        return response.map((dto) => ({
            ...dto,
            observationDate: fromIsoToDateTime(dto.observationDate),
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async addRevision(data: AddRevisionRequest, isTenant: boolean) {
        const formData = new FormData()

        formData.set("propertyId", data.propertyId.toString())
        if (data.rentingPeriodId) {
            formData.set("rentingPeriodId", data.rentingPeriodId.toString())
        }
        formData.set("name", data.name.toString())
        formData.set("description", data.description.toString())
        formData.set("date", data.date)
        formData.set("visibleToTenant", data.visibleToTenant.toString())
        formData.set("allowTenantChoseDate", data.allowTenantChoseDate.toString())
        if (data.files) {
            data.files.forEach((f) => formData.append("files", f))
        }

        const response = await Api.post<PropertyRevisionDto>(isTenant ? "/maintenance/tenant" : "/maintenance", formData)
        return {
            ...response,
            date: fromIsoToDateTime(response.date),
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
            tenantChosenDate: response.tenantChosenDate != null ? fromIsoToDateTime(response.tenantChosenDate) : response.tenantChosenDate,
        }
    }

    public static async addInventorySectionItem(data: AddInventorySectionItemRequest, inventorySectionId: number) {
        const formData = new FormData()

        formData.set("itemName", data.itemName.toString())
        formData.set("itemDescription", data.itemDescription.toString())
        formData.set("purchaseDate", data.purchaseDate)
        formData.set("status", data.status.toString())
        if (data.purchaseValue != null) {
            formData.set("purchaseValue", data.purchaseValue.toString())
            formData.set("currencyId", data.currencyId.toString())
        }
        if (data.files) {
            data.files.forEach((f) => formData.append("files", f))
        }

        await Api.post<never>(`/inventory/sections/${inventorySectionId}/items`, formData)
    }

    public static async addInventorySection(data: AddInventorySectionRequest) {
        await Api.post("/inventory/sections", data)
    }

    public static async updateRevision({ revisionId, operations }: { revisionId: string; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<PropertyRevisionDto>(`/maintenance/${revisionId}`, operations)
        return {
            ...response,
            date: fromIsoToDateTime(response.date),
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
            tenantChosenDate: response.tenantChosenDate != null ? fromIsoToDateTime(response.tenantChosenDate) : response.tenantChosenDate,
        }
    }

    public static async blockUser({ userId }: { userId: string }) {
        return Api.delete<never>(`/user/deactivate/${userId}`)
    }

    public static async updateSendCodeViaSMS({ userId, phoneNumber, isRequired }: { userId: string; phoneNumber?: string; isRequired: boolean }) {
        return Api.put<never>(`/user/${userId}/phone`, { phoneNumber, isRequired })
    }

    public static async addTenant(data: AddTenantRequest) {
        const formData = new FormData()
        if (data.details?.propertyId != null) formData.set("propertyId", data.details.propertyId.toString())
        if (data.configuration?.shouldSendC168 != null) formData.set("shouldSendC168", data.configuration.shouldSendC168.toString())
        if (data.configuration?.createTenantAccount != null) {
            if (data.configuration.createTenantAccount) {
                formData.set("tenantFirstName", data.configuration.tenantFirstName!)
                formData.set("tenantLastName", data.configuration.tenantLastName!)
            }
            formData.set("createTenantAccount", data.configuration?.createTenantAccount.toString())
        }
        if (data.configuration?.internalId != null && data.configuration?.internalId !== "") {
            formData.set("internalId", data.configuration?.internalId.toString())
        }
        if (data.configuration?.scheduleMaintenanceAtHalfPeriod != null)
            formData.set("scheduleMaintenanceAtHalfPeriod", data.configuration.scheduleMaintenanceAtHalfPeriod.toString())
        if (data.details?.mails != null) uniq(data.details.mails).forEach((mail: string) => formData.append("mails", mail.toString()))
        if (data.details?.moveInDate != null) formData.set("moveInDate", data.details.moveInDate.toString())
        if (data.details?.moveOutDate != null) formData.set("moveOutDate", data.details.moveOutDate.toString())
        if (data.configuration != null) {
            formData.set("isRentExpenseAutomatic", data.configuration.isRentExpenseAutomatic.toString())
            formData.set("acceptPartialPayments", data.configuration.acceptPartialPayments?.toString() ?? "false")
            if (data.configuration.automaticRentExpenseCurrencyId && data.configuration.isRentExpenseAutomatic) {
                formData.set("automaticRentExpenseCurrencyId", data.configuration.automaticRentExpenseCurrencyId?.toString())
            }
        }
        if (data.rentDetails != null) {
            formData.set("value", data.rentDetails?.value.toString())
            if (data.rentDetails?.securityDeposit != null) formData.set("securityDeposit", data.rentDetails.securityDeposit.toString())
            if (data.rentDetails?.securityDepositCurrencyId != null)
                formData.set("securityDepositCurrencyId", data.rentDetails.securityDepositCurrencyId.toString())

            formData.set("currencyId", data.rentDetails.currencyId.toString())
            data.rentDetails.paymentPeriod.forEach((day: number) => formData.append("paymentPeriod", day.toString()))
        }
        if (data.contractData?.contractId != null) formData.set("contractId", data.contractData.contractId)
        if (data.contractData?.signature != null) formData.set("signature", data.contractData.signature)
        if (data.propertyInfo) {
            formData.set("propertyInfo.city", data.propertyInfo.city.toString())
            formData.set("propertyInfo.county", data.propertyInfo.county.toString())

            if (data.propertyInfo.street) formData.set("propertyInfo.street", data.propertyInfo.street.toString())
            if (data.propertyInfo.streetNumber) formData.set("propertyInfo.streetNumber", data.propertyInfo.streetNumber.toString())
            if (data.propertyInfo.apartment) formData.set("propertyInfo.apartment", data.propertyInfo.apartment.toString())
            if (data.propertyInfo.floor != null) formData.set("propertyInfo.floor", data.propertyInfo.floor.toString())
            if (data.propertyInfo.stair) formData.set("propertyInfo.stair", data.propertyInfo.stair.toString())
            if (data.propertyInfo.buildingNumber) formData.set("propertyInfo.buildingNumber", data.propertyInfo.buildingNumber.toString())
            if (data.propertyInfo.savePropertyInfo) formData.set("propertyInfo.savePropertyInfo", data.propertyInfo.savePropertyInfo.toString())
        }

        if (data.ownerContactInfo) {
            formData.set("ownerContactInfo.fullName", data.ownerContactInfo.fullName.toString())
            if (data.ownerContactInfo.floor) formData.set("ownerContactInfo.floor", data.ownerContactInfo.floor.toString())
            if (data.ownerContactInfo.apartment) formData.set("ownerContactInfo.apartment", data.ownerContactInfo.apartment.toString())
            if (data.ownerContactInfo.street) formData.set("ownerContactInfo.street", data.ownerContactInfo.street.toString())
            if (data.ownerContactInfo.stair) formData.set("ownerContactInfo.stair", data.ownerContactInfo.stair.toString())
            if (data.ownerContactInfo.streetNumber) formData.set("ownerContactInfo.streetNumber", data.ownerContactInfo.streetNumber.toString())
            if (data.ownerContactInfo.buildingNumber) formData.set("ownerContactInfo.buildingNumber", data.ownerContactInfo.buildingNumber.toString())
            formData.set("ownerContactInfo.county", data.ownerContactInfo.county.toString())
            formData.set("ownerContactInfo.city", data.ownerContactInfo.city.toString())
            if (data.ownerContactInfo.identityNumber) formData.set("ownerContactInfo.identityNumber", data.ownerContactInfo.identityNumber.toString())
            if (data.ownerContactInfo.identitySeries) formData.set("ownerContactInfo.identitySeries", data.ownerContactInfo.identitySeries.toString())
            if (data.ownerContactInfo.contactInfoPersonType)
                formData.set("ownerContactInfo.contactInfoPersonType", data.ownerContactInfo.contactInfoPersonType.toString())
            if (data.ownerContactInfo.companyName) formData.set("ownerContactInfo.companyName", data.ownerContactInfo.companyName.toString())
            if (data.ownerContactInfo.companyRegistrationNumber)
                formData.set("ownerContactInfo.companyRegistrationNumber", data.ownerContactInfo.companyRegistrationNumber.toString())
            if (data.ownerContactInfo.companyPosition)
                formData.set("ownerContactInfo.companyPosition", data.ownerContactInfo.companyPosition.toString())
            formData.set("ownerContactInfo.type", "0")
            if (data.ownerContactInfo.bankAccount) formData.set("bankAccount", data.ownerContactInfo.bankAccount.toString())
            if (data.ownerContactInfo.bankName) formData.set("bankName", data.ownerContactInfo.bankName.toString())
        }
        if (data.contractData?.contractId == null && data.contractData?.files != null) {
            data.contractData.files.forEach((f: CustomFile) => formData.append("files", f))
        }
        if (data.details?.invitationLanguage) {
            formData.set("notificationEmailLanguage", data.details.invitationLanguage)
        }

        if (data.meters) {
            data.meters.forEach((meter: { id: number; value: number }, index) => {
                formData.set(`meters[${index}].id`, meter.id.toString())
                formData.set(`meters[${index}].value`, meter.value.toString())
            })
        }

        const response = await Api.post<RentingPeriodDto>("/tenants", formData)
        const parsed: RentingPeriod = {
            ...response,
            invitedDate: response.invitedDate ? fromIsoToDateTime(response.invitedDate) : undefined,
            acceptedDate: response.acceptedDate ? fromIsoToDateTime(response.acceptedDate) : undefined,
            moveOutDate: fromIsoToDateTime(response.moveOutDate),
            moveInDate: fromIsoToDateTime(response.moveInDate),
            removeTenantAccessAt: response.removeTenantAccessAt ? fromIsoToDateTime(response.removeTenantAccessAt) : undefined,
        }
        return parsed
    }

    public static async tenantResponse({
        tenantId,
        response,
        leaveDate,
    }: {
        tenantId: number
        response: ExpirationReason
        leaveDate?: string | null
    }) {
        return Api.post<never>(`/tenants/${tenantId}/expiration-form`, { leaveDate, response })
    }

    public static async revisionTenantResponse({
        rentingPeriodId,
        date,
        maintenanceScheduleId,
    }: {
        rentingPeriodId: number
        date: string | null
        maintenanceScheduleId: number
    }) {
        return Api.post<never>(`/maintenance/tenant/${rentingPeriodId}`, { rentingPeriodId, date, maintenanceScheduleId })
    }

    public static async resendTenantInvitation(rentingPeriodId: number, email: string, notificationEmailLanguage: string) {
        return Api.put<never>("/tenants/resend-invitation", { rentingPeriodId, mail: email, notificationEmailLanguage })
    }

    public static async acceptOnTenantBehalf(rentingPeriodId: number, email: string, tenantFirstName: string, tenantLastName: string) {
        return Api.put<never>("/tenants/accept-on-tenant-behalf", { rentingPeriodId, mail: email, tenantFirstName, tenantLastName })
    }

    public static async removeTenantInvitation(rentingPeriodId: number, email: string) {
        return Api.put<never>("/tenants/remove-invitation", { rentingPeriodId, mail: email })
    }

    public static async removeAcceptedTenantInvitation(rentingPeriodId: number, email: string) {
        return Api.put<never>("/tenants/remove-accepted-tenant", { rentingPeriodId, mail: email })
    }
    public static async addInvitation(rentingPeriodId: number, email: string, notificationEmailLanguage: string) {
        return Api.put<never>("/tenants/add-tenant", { rentingPeriodId, mail: email, notificationEmailLanguage })
    }
    public static async addManagerInvitation(propertyId: number, email: string, type: ManagerType) {
        return Api.post<never>("/property/add-manager", { propertyId, mail: email, ownershipType: type })
    }

    public static async resendManagerInvitation(propertyId: number, email: string) {
        return Api.put<never>("/property/resend-manager-invitation", { propertyId, mail: email })
    }
    public static async removeManagerInvitation(propertyId: number, email: string) {
        return Api.put<never>("/property/remove-manager-invitation", { propertyId, mail: email })
    }

    public static async setAccountManager(propertyId: number, userId: string, isAccountManager: boolean) {
        return Api.post<never>(`/property/${propertyId}/set-account-manager`, { userId, isAccountManager })
    }

    public static async deleteManager(propertyId: number, email: string) {
        return Api.put<never>("/property/remove-manager", { propertyId, mail: email })
    }

    public static async updateSummaryCard(summaryCardId: number, newPosition: number) {
        return Api.put<never>(`/summary-cards/${summaryCardId}`, { order: newPosition })
    }

    public static async updateChart(chartId: number, newPosition: number) {
        return Api.put<never>(`/charts/${chartId}`, { order: newPosition })
    }

    public static async updateGroupSummaryCard(summaryCardId: number, newPosition: number, groupId: number, dashboardConfigId: number) {
        return Api.put<never>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigId}/summary-cards/${summaryCardId}`, {
            order: newPosition,
        })
    }

    public static async updateGroupChart(chartId: number, newPosition: number, groupId: number, dashboardConfigId: number) {
        return Api.put<never>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigId}/charts/${chartId}`, { order: newPosition })
    }

    public static async deleteProvider(providerId: string) {
        return Api.delete<never>(`/expense-providers/${providerId}`)
    }

    public static async deleteMeterProvider(providerId: string) {
        return Api.delete<never>(`/meter-providers/${providerId}`)
    }

    public static async deleteOblioAccount() {
        return Api.delete<never>("/user/oblio")
    }

    public static async updateOblioCompanyStatus(isActive: boolean, cif: string) {
        return Api.put<never>(`/user/oblio/${cif}/${isActive ? "deactivate" : "activate"}`, {})
    }

    public static async fetchUserSections(): Promise<IKanbanSection[]> {
        const response = await Api.get<IKanbanSectionDto[]>("/tasks/sections")
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
        }))
    }

    public static async fetchUserInvoicingConfigurations(): Promise<InvoicingConfiguration[]> {
        const response = await Api.get<InvoicingConfigurationDto[]>("/nomenclature/invoicing-configuration")
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchUserInvoicingConfigurationCompanies({ queryKey }: QueryFunctionContext): Promise<InvoicingConfigurationCompany[]> {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        const response = await Api.get<InvoicingConfigurationCompanyDto[]>(`/nomenclature/invoicing-configuration/${id}/companies`)
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchUserInvoicingConfigurationUsers({ queryKey }: QueryFunctionContext): Promise<InvoicingConfigurationUser[]> {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        const response = await Api.get<InvoicingConfigurationUserDto[]>(`/nomenclature/invoicing-configuration/${id}/users`)
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchAllUserInvoicingCompanies(): Promise<InvoicingConfigurationCompany[]> {
        const response = await Api.get<InvoicingConfigurationCompanyDto[]>("/nomenclature/invoicing-configuration/companies")
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchInvoicingClientById({ queryKey }: QueryFunctionContext): Promise<InvoicingConfigurationClient | null> {
        const [_, data] = queryKey
        const { invoicingClientId } = data as {
            invoicingClientId: number
        }

        const response = await Api.get<PaginatedData<InvoicingConfigurationClientDto>>(
            `/nomenclature/invoicing-configuration/clients/odata?$filter=invoicingClientId eq ${invoicingClientId}`,
        )

        return (
            response.items
                .map((dto) => ({
                    ...dto,
                    createdAt: fromIsoToDateTime(dto.createdAt),
                    lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
                }))
                .at(0) ?? null
        )
    }

    public static async fetchAllUserInvoicingClients({ queryKey }: QueryFunctionContext): Promise<PaginatedData<InvoicingConfigurationClient>> {
        const [_, data] = queryKey
        const { page, pageSize, sortingColumns, filters } = data as {
            page: number
            pageSize: number
            sortingColumns: GridSortModel
            filters?: GridFilterModel
        }

        const filteredSortingColumns = sortingColumns.filter((c) => c.sort != null)
        let orderBy = ""
        let filterBy = ""

        if (filteredSortingColumns.length > 0) {
            orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        }

        if ((filters?.items.length ?? 0) > 0) {
            filterBy = `$filter=${filters?.items
                .map((i) => {
                    if (i.operator === "startswith") {
                        return `startswith(${i.field}, '${i.value}')`
                    } else if (i.operator === "notstartswith") {
                        return `not startswith(${i.field}, '${i.value}')`
                    }
                    return `${i.field} ${Api.muiFilterOperatorToOdata(i.operator)} '${i.value}'`
                })
                .join(" " + filters.logicOperator + " ")}`
        } else {
            filterBy = ""
        }

        const queryParams = [`$skip=${page * pageSize}`, `$top=${pageSize}`, orderBy, filterBy].filter(Boolean).join("&")

        const response = await Api.get<PaginatedData<InvoicingConfigurationClientDto>>(
            `/nomenclature/invoicing-configuration/clients/odata?${queryParams}`,
        )

        return {
            ...response,
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
            })),
        }
    }

    public static async fetchCompanySeries({ queryKey }: QueryFunctionContext): Promise<InvoicingConfigurationSeries[]> {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        const response = await Api.get<InvoicingConfigurationSeriesDto[]>(`/nomenclature/invoicing-configuration/companies/${id}/series`)
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchCompanyVATs({ queryKey }: QueryFunctionContext): Promise<InvoicingConfigurationVAT[]> {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        return Api.get<InvoicingConfigurationVAT[]>(`/nomenclature/invoicing-configuration/companies/${id}/vat-rates`)
    }

    public static async fetchGroupSummaryCards({ queryKey }: QueryFunctionContext): Promise<SummaryCardType[]> {
        const [_, data] = queryKey
        const { groupId } = data as { groupId: number }
        return await Api.get<SummaryCardType[]>(`/groups/${groupId}/summary-cards`)
    }

    public static async fetchCompanyClients({ queryKey }: QueryFunctionContext): Promise<InvoicingConfigurationClient[]> {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        const response = await Api.get<InvoicingConfigurationClientDto[]>(`/nomenclature/invoicing-configuration/companies/${id}/clients`)
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastModifiedAt: fromIsoToDateTime(dto.lastModifiedAt),
        }))
    }

    public static async fetchDryRunInvoices({ queryKey }: QueryFunctionContext): Promise<InvoicingDryRun[]> {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        const response = await Api.get<InvoicingDryRunDto[]>(`/nomenclature/invoicing-configuration/invoices/${id}/dry-run`)
        return response.map((dto) => ({
            ...dto,
            date: dto.date ? fromIsoToDateTime(dto.date) : undefined,
            dueDate: dto.dueDate ? fromIsoToDateTime(dto.dueDate) : undefined,
        }))
    }

    public static async fetchPendingInvoiceStatus({ queryKey }: QueryFunctionContext): Promise<PendingInvoiceStatusResponse> {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        const response = await Api.get<PendingInvoiceStatusDto>(`/nomenclature/invoicing-configuration/invoice-status?queryId=${id}`)

        return {
            ...response,
            invoiceDate: fromIsoToDateTime(response.invoiceDate),
        }
    }

    public static async addNewUserSection({ data }: { data: AddSectionRequest }) {
        return await Api.post<never>("/tasks/sections", data)
    }

    public static async activateInvoicingCompany(invoicingConfigurationId: number, invoicingCompanyId: number) {
        return await Api.put<never>(`/nomenclature/invoicing-configuration/${invoicingConfigurationId}/companies/${invoicingCompanyId}/activate`, {})
    }

    public static async updateInvoicingConfigurationCompanies(invoicingConfigurationId: number) {
        return await Api.put<never>(`/nomenclature/invoicing-configuration/${invoicingConfigurationId}/update-companies`, {})
    }

    public static async updateInvoicingConfigurationCompany(invoicingConfigurationId: number, invoicingCompanyId: number) {
        return await Api.put<never>(`/nomenclature/invoicing-configuration/${invoicingConfigurationId}/companies/${invoicingCompanyId}/update`, {})
    }

    public static async deactivateInvoicingCompany(invoicingConfigurationId: number, invoicingCompanyId: number) {
        return await Api.put<never>(
            `/nomenclature/invoicing-configuration/${invoicingConfigurationId}/companies/${invoicingCompanyId}/deactivate`,
            {},
        )
    }

    public static async addTaskComment({ data }: { data: OwnerAddTaskCommentRequest }) {
        const formData = new FormData()

        formData.set("text", data.message.toString())
        data.files.forEach((f) => f != null && formData.append("files", f))

        await Api.post(`/tasks/sections/${data.sectionId}/tasks/${data.taskId}/comments`, formData)
    }

    public static async addNewInvoicingConfigurationRequest(data: AddNewInvoicingConfigurationRequest) {
        await Api.post("/nomenclature/invoicing-configuration", data)
    }

    public static async addNewInvoicingClientRequest(data: AddNewInvoicingClientRequest) {
        await Api.post("/nomenclature/invoicing-configuration/clients", { ...data, identifier: "Rentzz_" + uuidv4(), requestId: uuidv4() })
    }

    public static async tenantAddTaskComment({ data }: { data: TenantAddTaskCommentRequest }) {
        const formData = new FormData()

        formData.set("text", data.message.toString())
        data.files.forEach((f) => f != null && formData.append("files", f))

        await Api.post(`/tasks/tenant/${data.rentingPeriodId}/tasks/${data.taskId}/comments`, formData)
    }

    public static async assignOrRemoveUserToTaskItem({
        userId,
        type,
        sectionId,
        taskId,
    }: {
        userId: string
        type: "add" | "remove"
        taskId: string
        sectionId: string
    }) {
        await Api.put(`/tasks/sections/${sectionId}/tasks/${taskId}/${type === "add" ? "assign" : "remove"}/${userId}`, {})
    }

    public static async updateUserSections({ sectionId, operations }: { sectionId: string; operations: jsonpatch.Operation[] }) {
        await Api.patch(`/tasks/sections/${sectionId}`, operations)
    }

    public static async updateInvoicingConfiguration({
        invoicingConfigurationId,
        operations,
    }: {
        invoicingConfigurationId: number
        operations: jsonpatch.Operation[]
    }) {
        await Api.patch(`/nomenclature/invoicing-configuration/${invoicingConfigurationId}`, operations)
    }

    public static async updateInvoicingClient({ invoicingClientId, operations }: { invoicingClientId: number; operations: jsonpatch.Operation[] }) {
        await Api.patch(`/nomenclature/invoicing-configuration/clients/${invoicingClientId}`, operations)
    }

    public static async deleteInvoicingClient({ invoicingClientId }: { invoicingClientId: number }) {
        await Api.delete(`/nomenclature/invoicing-configuration/clients/${invoicingClientId}`)
    }

    public static async updateSectionTask({
        sectionId,
        taskId,
        operations,
    }: {
        sectionId: string | undefined
        taskId: string
        operations: jsonpatch.Operation[]
    }) {
        await Api.patch(`/tasks/sections/${sectionId}/tasks/${taskId}`, operations)
    }

    public static async updateTenantTask({
        rentingPeriodId,
        taskId,
        operations,
    }: {
        rentingPeriodId: number
        taskId: string
        operations: jsonpatch.Operation[]
    }) {
        await Api.patch(`/tasks/tenant/${rentingPeriodId}/tasks/${taskId}`, operations)
    }

    public static async fetchItemsSection(
        sort: keyof IKanbanTask,
        sectionId: string,
        taskFilterState: TasksFilterOptions,
        userId?: string,
    ): Promise<IKanbanTask[]> {
        const filter = taskFilterState === TasksFilterOptions.Mine ? `$filter=assignations/any(u:u/userId eq '${userId}')` : ""
        let order = ""
        if (sort === "createdAt") {
            order = "$orderBy=createdAt asc"
        } else if (sort === "deadline") {
            order = "$orderBy=IsNullDeadline asc, deadline asc, createdAt asc"
        }

        const response = await Api.get<IKanbanTaskDto[]>(`/tasks/sections/${sectionId}/tasks?${order}&${filter}`)
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
            deadline: dto.deadline ? fromIsoToDateTime(dto.deadline) : undefined,
            lastOpenedAtByTenant: dto.lastOpenedAtByTenant ? fromIsoToDateTime(dto.lastOpenedAtByTenant) : undefined,
        }))
    }

    public static async fetchTenantTasks({ queryKey }: QueryFunctionContext): Promise<IKanbanTask[]> {
        const [_, data] = queryKey
        const { rentingPeriodId } = data as { rentingPeriodId: number }
        const response = await Api.get<IKanbanTaskDto[]>(`/tasks/tenant/${rentingPeriodId}`)
        return response.map((dto) => ({
            ...dto,
            deadline: dto.deadline ? fromIsoToDateTime(dto.deadline) : undefined,
            createdAt: fromIsoToDateTime(dto.createdAt),
            lastOpenedAtByTenant: dto.lastOpenedAtByTenant ? fromIsoToDateTime(dto.lastOpenedAtByTenant) : undefined,
        }))
    }

    public static async fetchTaskComments({ queryKey }: QueryFunctionContext): Promise<CommentType[]> {
        const [_, data] = queryKey
        const { sectionId, taskId } = data as { sectionId: string; taskId: string }
        const response = await Api.get<CommentTypeDto[]>(`/tasks/sections/${sectionId}/tasks/${taskId}/comments`)
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
        }))
    }

    public static async fetchTaskPossibleUsers({ queryKey }: QueryFunctionContext): Promise<TaskAssignedUser[]> {
        const [_, data] = queryKey
        const { sectionId, taskId } = data as { sectionId: string; taskId: string }
        const response = await Api.get<TaskAssignedUser[]>(`/tasks/sections/${sectionId}/tasks/${taskId}/possible-users`)
        return response
    }

    public static async fetchTenantTaskComments({ queryKey }: QueryFunctionContext): Promise<CommentType[]> {
        const [_, data] = queryKey
        const { rentingPeriodId, taskId } = data as { rentingPeriodId: number; taskId: string }
        const response = await Api.get<CommentTypeDto[]>(`/tasks/tenant/${rentingPeriodId}/tasks/${taskId}/comments`)
        return response.map((dto) => ({
            ...dto,
            createdAt: fromIsoToDateTime(dto.createdAt),
        }))
    }

    public static async addNewSectionTask({ data }: { data: AddTaskRequest | TenantAddTaskRequest }) {
        const formData = new FormData()

        formData.set("name", data.name.toString())
        if ("deadline" in data && data.deadline != null) {
            formData.set("deadline", data.deadline.toString())
        }
        formData.set("sectionId", (data as AddTaskRequest).sectionId.toString())
        if (data.description) formData.set("description", data.description.toString())
        if ((data as AddTaskRequest).propertyId) formData.set("propertyId", (data as AddTaskRequest).propertyId.toString())
        if (data.rentingPeriodId) formData.set("rentingPeriodId", data.rentingPeriodId.toString())
        data.files.forEach((f) => f != null && formData.append("files", f))

        return await Api.post<never>(`/tasks/sections/${(data as AddTaskRequest).sectionId}/tasks`, formData)
    }

    public static async tenantAddNewTask({ data }: { data: TenantAddTaskRequest }) {
        const formData = new FormData()

        formData.set("name", data.name.toString())
        if (data.description) formData.set("description", data.description.toString())
        if (data.rentingPeriodId) formData.set("rentingPeriodId", data.rentingPeriodId.toString())
        data.files.forEach((f) => f != null && formData.append("files", f))

        return await Api.post<never>(`/tasks/tenant/${data.rentingPeriodId}/tasks`, formData)
    }

    public static async fetchMeters(id: number) {
        const response = await Api.get<OwnerPropertyMeterDto[]>(`/meters?$filter=propertyId eq ${id}`)
        const parsed: OwnerPropertyMeter[] = response.map((dto) => ({
            ...dto,
            lastModified: fromIsoToDateTime(dto.lastModified),
        }))
        return parsed
    }

    public static async fetchMetersAsTenant(id: number, rentingPeriodId: number) {
        const response = await Api.get<TenantPropertyMeterDto[]>(
            `/meters/tenant-meters?$filter=propertyId eq ${id}&rentingPeriodId=${rentingPeriodId}`,
        )
        const parsed: TenantPropertyMeter[] = response.map((dto) => ({
            ...dto,
            lastModified: dto.lastModified ? fromIsoToDateTime(dto.lastModified) : undefined,
        }))
        return parsed
    }

    public static async fetchMeterValuesHistory({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { meterId, page, pageSize, sortingColumns, rentingPeriodId } = data as {
            meterId: number
            page: number
            pageSize: number
            sortingColumns: GridSortModel
            rentingPeriodId: number | undefined
        }
        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        let url = `/meters/${meterId}/values?${orderBy}$skip=${page * pageSize}&$top=${pageSize}`
        if (rentingPeriodId) {
            url += `&rentingPeriodId=${rentingPeriodId}`
        }
        const response = await Api.get<PaginatedData<PropertyMeterValueDto>>(url)

        const parsed: PaginatedData<PropertyMeterValue> = {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                sentToProviderAt: dto.sentToProviderAt ? fromIsoToDateTime(dto.sentToProviderAt) : undefined,
                sendAutomaticallyAt: dto.sendAutomaticallyAt ? fromIsoToDateTime(dto.sendAutomaticallyAt) : undefined,
            })),
            count: response.count,
        }
        return parsed
    }

    public static async fetchPendingMeterValues({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { page, pageSize, sortingColumns } = data as { page: number; pageSize: number; sortingColumns: GridSortModel }
        let orderBy = ""
        if (sortingColumns.length > 0) orderBy = `$orderby=${sortingColumns.map((c) => `${c.field} ${c.sort}`).join(",")}&`
        else orderBy = ""

        const response = await Api.get<PaginatedData<PropertyMeterValueDto>>(
            `/meters/readings?$filter=status ne 'Sent'&${orderBy}$skip=${page * pageSize}&$top=${pageSize}`,
        )

        const parsed: PaginatedData<PropertyMeterValue> = {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                sentToProviderAt: dto.sentToProviderAt ? fromIsoToDateTime(dto.sentToProviderAt) : undefined,
                sendAutomaticallyAt: dto.sendAutomaticallyAt ? fromIsoToDateTime(dto.sendAutomaticallyAt) : undefined,
            })),
            count: response.count,
        }
        return parsed
    }

    public static async fetchInfinitePendingMeterValues({ pageParam }: QueryFunctionContext & { pageParam: number }) {
        const lastItemIndex = pageParam as number | undefined
        const response = await Api.get<PaginatedData<PropertyMeterValueDto>>(
            `/meters/readings/?$filter=status ne 'Sent' &$skip=${lastItemIndex ?? 0}&$top=8`,
        )

        const parsed: PaginatedData<PropertyMeterValue> = {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                sentToProviderAt: dto.sentToProviderAt ? fromIsoToDateTime(dto.sentToProviderAt) : undefined,
                sendAutomaticallyAt: dto.sendAutomaticallyAt ? fromIsoToDateTime(dto.sendAutomaticallyAt) : undefined,
            })),
            count: response.count,
        }
        return parsed
    }

    public static async fetchInfiniteMeterValuesHistory({ pageParam, queryKey }: QueryFunctionContext & { pageParam: number }) {
        const lastItemIndex = pageParam as number | undefined
        const [_, data] = queryKey
        const { meterId, rentingPeriodId } = data as { meterId: number; rentingPeriodId: number | undefined }
        let url = `/meters/${meterId}/values?$orderby=createdAt desc&$skip=${lastItemIndex ?? 0}&$top=8`

        if (rentingPeriodId) {
            url += `&rentingPeriodId=${rentingPeriodId}`
        }
        const response = await Api.get<PaginatedData<PropertyMeterValueDto>>(url)

        const parsed: PaginatedData<PropertyMeterValue> = {
            items: response.items.map((dto) => ({
                ...dto,
                createdAt: fromIsoToDateTime(dto.createdAt),
                sentToProviderAt: dto.sentToProviderAt ? fromIsoToDateTime(dto.sentToProviderAt) : undefined,
                sendAutomaticallyAt: dto.sendAutomaticallyAt ? fromIsoToDateTime(dto.sendAutomaticallyAt) : undefined,
            })),
            count: response.count,
        }
        return parsed
    }

    public static async fetchCurrencies() {
        return Api.get<Currency[]>("/currency")
    }

    public static async updateEntityFileName(fileId: string, newFileName: string): Promise<string> {
        return Api.put<string>(`/files/${fileId}`, { newFileName })
    }

    public static async fetchCurrencyExchangeRate({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { fromCurrencyId, toCurrencyId, date } = data as { fromCurrencyId: number; toCurrencyId: number; date: string }
        return Api.get<number>(`/currency/rate?fromCurrencyId=${fromCurrencyId}&toCurrencyId=${toCurrencyId}&date=${date}`)
    }

    public static async getSubscriptions(): Promise<SubscriptionDto[]> {
        return Api.get<SubscriptionDto[]>("/subscriptions")
    }

    public static async getSubscriptionCheckoutLink(id: string, promotionalCode?: string): Promise<string> {
        return Api.post<string>(`/subscriptions/${id}?${promotionalCode ? `promotionalCodeUsed=${promotionalCode}` : ""}`, {})
    }

    public static async updateFileName(propertyId: number, fileId: string, newFileName: string): Promise<string> {
        return Api.put<string>(`/property/${propertyId}/files/${fileId}`, { newFileName })
    }
    public static async seenByTenant(rentingPeriodId: number, taskId: string) {
        return Api.put<never>(`/tasks/tenant/${rentingPeriodId}/tasks/${taskId}`, {})
    }

    public static fetchUnits() {
        return Api.get<Unit[]>("/units")
    }

    public static async fetchCounties() {
        return Api.get<{ countyId: number; name: string }[]>("/geo/counties")
    }

    public static async fetchPropertyPhotos({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { propertyId } = data as { propertyId: number }
        const response = await Api.get<PresentationFile[]>(`/property/${propertyId}/presentation-files`)
        return orderBy(response, "position")
    }
    public static async downloadMultiFile(fileIds: string[]) {
        return Api.getBlob(`/files/download?fileIds=${fileIds.join("&fileIds=")}`)
    }

    public static async downloadTenantBalanceReport({ rentingPeriodId, from, to, format, balanceVersion }: ExportBalanceRequest) {
        return Api.getBlob(`/tenants/${rentingPeriodId}/balance-details?from=${from}&to=${to}&exportType=${format}&format=${balanceVersion}`)
    }

    public static async downloadExpenseExport(data: ExportExpensesRequest) {
        return Api.postGetBlob("/expenses/export", data)
    }

    public static async downloadC168(data: FormData) {
        return Api.postGetBlobC168("/create", data)
    }

    public static async downloadIncomeExport(data: ExportIncomeRequest) {
        return Api.postGetBlob("/income/export", data)
    }
    public static async downloadReadingExport(data: ExportReadingRequest) {
        return Api.postGetBlob(`/meters/${data.meterId}/export`, data)
    }

    public static async downloadRentingPeriodData(id: string, key: string) {
        return Api.getBlob(`/files/download-export?downloadId=${id}&downloadCheck=${key}`)
    }

    public static async getEntityFiles(entityId: string, entityType: PropertyFileType) {
        return Api.get<PropertyFileDto[]>(`/files?entityId=${entityId}&entityType=${entityType}`)
    }

    public static groupPropertyFiles(response: PropertyFileDto[] | { count: number; items: PropertyFileDto[] }) {
        if (!Array.isArray(response)) response = response.items
        return {
            expenses: response
                .filter((f) => f.type === PropertyFileType.Expense)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
            income: response
                .filter((f) => f.type === PropertyFileType.Income)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
            documents: response
                .filter((f) => f.type === PropertyFileType.Document)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
            contracts: response
                .filter((f) => f.type === PropertyFileType.Contract)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
            insurance: response
                .filter((f) => f.type === PropertyFileType.Insurance)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
            meterValues: response
                .filter((f) => f.type === PropertyFileType.MeterValue)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
            tasks: response
                .filter((f) => f.type === PropertyFileType.TaskFile)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
            observations: response
                .filter((f) => f.type === PropertyFileType.RevisionObservation)
                .map((e) => ({
                    id: e.id,
                    name: e.name,
                    size: e.size,
                    extension: e.extension,
                })),
        }
    }

    public static async getTenantPropertyFiles(id?: number): Promise<PropertyFilesGrouped> {
        if (id === undefined) throw new Error("Property id not specified")

        const response = await Api.get<PropertyFileDto[]>(`/tenants/${id}/files`)

        return Api.groupPropertyFiles(response)
    }

    public static async getOwnerPropertyFiles(id?: number, rentingPeriodId?: number): Promise<PropertyFilesGrouped> {
        if (id === undefined) throw new Error("Property id not specified")

        let url: string
        if (rentingPeriodId) {
            url = `/files/renting-period/${rentingPeriodId}`
        } else {
            url = `/property/${id}/files`
        }
        const response = await Api.get<PropertyFileDto[]>(url)

        return Api.groupPropertyFiles(response)
    }

    public static async fetchCities({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { id } = data as { id: number }
        return Api.get<
            {
                cityId: number
                countyId: number
                name: string
            }[]
        >(`/geo/counties/${id}/cities?$orderby=Name asc`)
    }

    public static async fetchAreas({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { id, countyId } = data as { id: number; countyId: number }
        return Api.get<
            {
                areaId: number
                cityId: number
                name: string
            }[]
        >(`/geo/counties/${countyId}/cities/${id}/areas?$orderby=Name asc`)
    }

    public static async fetchPossiblePropertyManagers({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { propertyId } = data as { propertyId: number }
        return Api.get<TaskAssignedUser[]>(`/property/${propertyId}/possible-account-managers`)
    }

    public static async uploadProfilePhoto(data: UploadProfilePictureRequest) {
        const formData = new FormData()
        formData.set("file", data.file)

        await Api.post<never>("/user/profile-photo", formData)
    }

    public static async deleteProfilePhoto() {
        await Api.delete<never>("/user/profile-photo")
    }

    public static async generateLateFeesExpense(data: GenerateExpenseLateFeesRequest, expenseId: number) {
        const formData = new FormData()
        formData.set("name", data.name)
        formData.set("date", data.date.split("T")[0])
        formData.set("dueDate", data.dueDate.split("T")[0])
        formData.set("shouldCreateInvoice", (data.shouldCreateInvoice ?? false).toString())
        formData.set("value", data.value.toString())
        formData.set("currencyId", data.currencyId.toString())
        if (data.description) formData.set("description", data.description)
        data.files.forEach((f) => formData.append("files", f))
        if (data.shouldCreateInvoice) {
            formData.set("invoiceProductName", data.invoiceProductName ?? "")
            formData.set("invoiceProductDescription", data.invoiceProductDescription ?? "")
        }

        await Api.post<PropertyExpenseDto>(`/expenses/${expenseId}/late-fees`, formData)
    }

    public static async addExpense(data: AddExpenseRequest) {
        const formData = new FormData()
        formData.set("value", data.value.toString())
        formData.set("date", data.date.split("T")[0])
        formData.set("name", data.name)
        if (data.description) formData.set("description", data.description)
        formData.set("shouldCreateInvoice", (data.shouldCreateInvoice ?? false).toString())
        if (data.shouldCreateInvoice) {
            formData.set("invoicingData.providerInvoiceDate", (data.invoicingData as any).providerInvoiceDate.split("T")[0])
            formData.set("invoicingData.providerInvoiceNumber", (data.invoicingData as any).providerInvoiceNumber)
            formData.set("invoicingData.providerInvoiceAmount", (data.invoicingData as any).providerInvoiceAmount)
        }
        formData.set("assignee", data.assignee.toString())
        formData.set("currencyId", data.currencyId.toString())
        if (data.propertyId) formData.set("propertyId", data.propertyId.toString())
        if (data.groupId) formData.set("groupId", data.groupId.toString())
        if (data.rentingPeriodId) formData.set("rentingPeriodId", data.rentingPeriodId.toString())
        formData.set("paymentType", data.paymentType.toString())
        if (data.paymentSubtype) formData.set("paymentSubtype", data.paymentSubtype.toString())
        formData.set("dueDate", data.dueDate.split("T")[0])
        if (data.assignee === ExpenseAssignee.TENANT && data.shouldNotifyOtherParty) {
            formData.set("shouldNotifyOtherParty", data.shouldNotifyOtherParty.toString())
        }

        if (data.labelId) {
            formData.set("labelId", data.labelId.toString())
        }
        if (data.associatedProviderId) {
            formData.set("associatedProviderId", data.associatedProviderId.toString())
        }

        data.files.forEach((f) => formData.append("files", f))

        const response = await Api.post<PropertyExpenseDto>("/expenses", formData)
        const parsed: PropertyExpense = {
            ...response,
            date: fromIsoToDateTime(response.date),
            dueDate: fromIsoToDateTime(response.dueDate),
            dateAutomaticallySentToTenant: response.dateAutomaticallySentToTenant
                ? fromIsoToDateTime(response.dateAutomaticallySentToTenant)
                : undefined,
            providerInvoiceDate: response.providerInvoiceDate ? fromIsoToDateTime(response.providerInvoiceDate) : undefined,
            propertyIncomes: response.propertyIncomes.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
        }
        return parsed
    }

    public static async addConfiguredExpenseProvider(data: AddProviderRequest) {
        await Api.post<never>("/expense-providers", data)
    }

    public static async addC168Address(data: PersonalData) {
        await Api.post<never>("/nomenclature/c168", data)
    }

    public static async addConfiguredMeterProvider(data: AddProviderRequest) {
        await Api.post<never>("/meter-providers", data)
    }

    public static async addRecurringExpense(data: AddRecurringExpenseRequest) {
        const formData = new FormData()
        formData.set("name", data.name)
        formData.set("assignee", data.assignee.toString())
        formData.set("recurringType", data.recurringType.toString())
        formData.set("creationDay", data.creationDay.toString())
        formData.set("dueDay", data.dueDay.toString())
        formData.set("value", data.value.toString())
        formData.set("currencyId", data.currencyId.toString())
        formData.set("propertyId", data.propertyId.toString())
        formData.set("shouldNotifyOtherParty", data.shouldNotifyOtherParty.toString())
        formData.set("generateInvoice", data.shouldGenerateInvoice.toString())

        if (data.paymentType) formData.set("paymentType", data.paymentType.toString())
        if (data.paymentSubtype) formData.set("paymentSubtype", data.paymentSubtype.toString())
        if (data.expirationDate) formData.set("expiresAt", data.expirationDate.toString())
        if (data.invoiceCompany) formData.set("invoicingCompanyId", data.invoiceCompany.toString())
        if (data.invoiceClient) formData.set("invoicingClientId", data.invoiceClient.toString())
        if (data.invoicingSeries) formData.set("invoicingSeriesId", data.invoicingSeries.toString())
        if (data.invoiceLanguage) formData.set("invoicingLanguage", data.invoiceLanguage.toString())
        if (data.invoiceCurrencyId) formData.set("invoicingCurrencyId", data.invoiceCurrencyId.toString())
        if (data.invoiceTVA) formData.set("invoicingVATId", data.invoiceTVA.toString())
        if (data.invoiceProductName) formData.set("invoicingProductName", data.invoiceProductName.toString())
        if (data.invoiceProductDescription) formData.set("invoicingProductDescription", data.invoiceProductDescription.toString())
        if (data.invoicingPeriodStart) formData.set("invoicingPeriodStart", data.invoicingPeriodStart.toString())
        if (data.invoicingPeriodEnd) formData.set("invoicingPeriodEnd", data.invoicingPeriodEnd.toString())

        if (data.labelId != null) {
            formData.set("labelId", data.labelId.toString())
        }
        if (data.associatedProviderId != null) {
            formData.set("associatedProviderId", data.associatedProviderId.toString())
        }

        data.files.forEach((f) => formData.append("files", f))

        await Api.post<RecurringExpenseDto>("/expenses/recurring", formData)
    }

    public static async addNote(data: AddNoteRequest) {
        const formData = new FormData()

        formData.set("propertyId", data.propertyId.toString())
        formData.set("title", data.title.toString())
        formData.set("text", data.text.toString())
        formData.set("isVisibleToTenant", data.isVisibleToTenant.toString())
        if (data.files) {
            data.files.forEach((f) => formData.append("files", f))
        }

        const response = await Api.post<NotesDto>("/notes", formData)
        const parsed: Notes = {
            ...response,
            addedAt: fromIsoToDateTime(response.addedAt),
            updatedAt: fromIsoToDateTime(response.updatedAt),
        }
        return parsed
    }

    public static async addObservation(data: AddObservationRequest) {
        const formData = new FormData()

        formData.set("maintenanceScheduleId", data.maintenanceScheduleId.toString())
        formData.set("observationDate", data.observationDate.toString())
        formData.set("description", data.description.toString())
        formData.set("title", data.title.toString())
        if (data.files) {
            data.files.forEach((f) => formData.append("files", f))
        }

        const response = await Api.post<RevisionObservationDto>(`/maintenance/${data.maintenanceScheduleId}/observations`, formData)
        return {
            ...response,
            observationDate: fromIsoToDateTime(response.observationDate),
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
        }
    }

    public static async updateObservation({
        revisionId,
        observationId,
        operations,
    }: {
        revisionId: string
        observationId: number
        operations: jsonpatch.Operation[]
    }) {
        const response = await Api.patch<RevisionObservationDto>(`/maintenance/${revisionId}/observations/${observationId}`, operations)
        return {
            ...response,
            observationDate: fromIsoToDateTime(response.observationDate),
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
        }
    }

    public static async updateInventorySection({
        inventorySectionId,
        operations,
    }: {
        inventorySectionId: number
        operations: jsonpatch.Operation[]
    }) {
        await Api.patch(`/inventory/sections/${inventorySectionId}`, operations)
    }

    public static async updateInventorySectionItem({
        inventorySectionId,
        inventoryItemId,
        operations,
    }: {
        inventorySectionId: number
        inventoryItemId: number
        operations: jsonpatch.Operation[]
    }) {
        await Api.patch(`/inventory/sections/${inventorySectionId}/items/${inventoryItemId}`, operations)
    }

    public static async addNotificationTemplate(data: AddNewDocumentRequest) {
        const formData = new FormData()

        formData.set("name", data.name.toString())
        if (data.content) {
            formData.set("content", data.content.toString())
        }

        return await Api.post<never>("/payment-notifications", formData)
    }

    public static async addGeneralDocument(data: AddNewDocumentRequest) {
        const formData = new FormData()

        formData.set("name", data.name.toString())
        if (data.content) {
            formData.set("content", data.content.toString())
        }

        if (data.files) {
            formData.append("file", data.files[0])
        }

        return await Api.post<never>("/general-documents", formData)
    }

    public static async addBankStatement(data: AddBankStatementRequest) {
        const formData = new FormData()

        formData.set("bankAccountId", data.bankAccountId.toString())
        if (data.files) {
            formData.append("file", data.files[0])
        }

        return await Api.post<never>("/statements", formData)
    }

    public static async associateStatementTransactionToExpense(
        statementId: number,
        transactionId: number,
        data: AssociateExpenseToStatementTransactionRequest,
    ) {
        return await Api.post<never>(`/statements/${statementId}/transactions/${transactionId}/expenses`, data)
    }

    public static async addGroup(data: AddGroupRequest) {
        return await Api.post<never>("/groups", { name: data.name, description: data.description, properties: data.properties })
    }

    public static async addGroupDashboardConfiguration(data: AddGroupDashboardConfigurationRequest, groupId: number) {
        return await Api.post<never>(`/groups/${groupId}/dashboard-configurations`, data)
    }

    public static async deleteGroupDashboardConfiguration(dashboardConfigurationId: number, groupId: number) {
        return await Api.delete<never>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigurationId}`)
    }

    public static async refreshGroupDashboardConfigurationSecrets(dashboardConfigurationId: number, groupId: number) {
        return await Api.put<never>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigurationId}/refresh`, {})
    }

    public static async updateGroupDashboardConfigurationPubliclyAvailable(
        dashboardConfigurationId: number,
        groupId: number,
        isPubliclyAvailable: boolean,
    ) {
        return await Api.put<never>(
            `/groups/${groupId}/dashboard-configurations/${dashboardConfigurationId}/update-public-access?isPubliclyAvailable=${isPubliclyAvailable}`,
            {},
        )
    }

    public static async sendTenantNotification(content: string, subject: string, to: string[], files?: CustomFile[], rentingPeriodId?: number) {
        const formData = new FormData()

        formData.set("content", content.toString())
        formData.set("subject", subject.toString())
        to.forEach((email) => formData.append("to", email))

        if (files && files.length > 0) {
            files.forEach((f) => formData.append("attachments", f))
        } else {
            formData.append("attachments", "[]")
        }

        return await Api.post<never>(`/tenants/${rentingPeriodId}/send-notification`, formData)
    }

    public static async updateGroup({ groupId, operations }: { groupId: number; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<UserGroupDto>(`/groups/${groupId}`, operations)
        const parsed: UserGroup = {
            ...response,
            dashboardSharingSettings: response.dashboardSharingSettings.map((dss) => ({
                ...dss,
                createdAt: fromIsoToDateTime(dss.createdAt),
                expirationDate: dss.expirationDate ? fromIsoToDateTime(dss.expirationDate) : undefined,
                lastModifiedAt: fromIsoToDateTime(dss.lastModifiedAt),
            })),
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
        }
        return parsed
    }

    public static async updateBankStatementTransaction({
        transactionId,
        statementId,
        operations,
    }: {
        transactionId: number
        statementId: number
        operations: jsonpatch.Operation[]
    }) {
        await Api.patch<UserGroupDto>(`/statements/${statementId}/transactions/${transactionId}`, operations)
    }

    public static async updateGroupProperties({
        groupId,
        propertyId,
        operationType,
    }: {
        groupId: number
        propertyId: number
        operationType: number
    }) {
        const response = await Api.put<UserGroupDto>(`/groups/${groupId}/properties`, { groupId, propertyId, operationType })
        const parsed: UserGroup = {
            ...response,
            dashboardSharingSettings: response.dashboardSharingSettings.map((dss) => ({
                ...dss,
                createdAt: fromIsoToDateTime(dss.createdAt),
                expirationDate: dss.expirationDate ? fromIsoToDateTime(dss.expirationDate) : undefined,
                lastModifiedAt: fromIsoToDateTime(dss.lastModifiedAt),
            })),
            createdAt: fromIsoToDateTime(response.createdAt),
            lastModifiedAt: fromIsoToDateTime(response.lastModifiedAt),
        }
        return parsed
    }

    public static async addGroupAccess({ groupId, email, accessType }: { groupId: number; email: string; accessType: ManagerType }) {
        await Api.put<never>(`/groups/${groupId}/access`, { email, accessType })
    }

    public static async addInvoicingConfigurationAccess({
        invoicingConfigurationId,
        email,
        accessType,
    }: {
        invoicingConfigurationId: number
        email: string
        accessType: ManagerType
    }) {
        await Api.put<never>(`/nomenclature/invoicing-configuration/${invoicingConfigurationId}/users`, { email, accessType })
    }

    public static async generateQueuedInvoices({
        invoicingConfigurationId,
        invoicingCompanyId,
        isTest,
    }: {
        invoicingConfigurationId: number
        invoicingCompanyId: number
        isTest: boolean
    }): Promise<GenerateQueuedInvoiceResponse[]> {
        const result = await Api.post<GenerateQueuedInvoiceDto[]>(
            `/nomenclature/invoicing-configuration/${invoicingConfigurationId}/companies/${invoicingCompanyId}/generate-invoices-new?isTest=${isTest}`,
            {},
        )

        return result.map((r) => ({ ...r, invoiceDate: r.invoiceDate != null ? fromIsoToDateTime(r.invoiceDate) : r.invoiceDate }))
    }

    public static async stopGeneratedInvoice({ queryId }: { queryId: string }) {
        await Api.put<never[]>(`/nomenclature/invoicing-configuration/stop-queued-invoice?queryId=${queryId}`, {})
    }

    public static async deleteGroupAccess({ groupId, userId }: { groupId: number; userId: string }) {
        await Api.delete(`/groups/${groupId}/access?userId=${userId}`)
    }

    public static async updatePropertyPercentage({ percentage, groupId, propertyId }: { percentage: number; groupId: number; propertyId: number }) {
        return await Api.post<never>(`/groups/${groupId}/properties/${propertyId}`, { expenseShare: percentage / 100 })
    }

    public static async createNewProvider({ data }: { data: CreateNewProviderRequest }) {
        return await Api.post<never>("/nomenclature/providers", data)
    }

    public static async createNewBankAccount({ data }: { data: AddBankAccountRequest }) {
        const formData = new FormData()

        formData.set("holder", data.holder.toString())
        formData.set("iban", data.iban.toString())
        formData.set("currencyId", data.currencyId.toString())
        formData.set("bank", data.bank.toString())

        if (data.properties) {
            data.properties.forEach((propertyId) => formData.append("properties", propertyId.toString()))
        }

        return await Api.post<never>("/nomenclature/bank-account", formData)
    }

    public static async updateBankAccount({ bankAccountId, operations }: { bankAccountId: number; operations: jsonpatch.Operation[] }) {
        await Api.patch(`/nomenclature/bank-account/${bankAccountId}`, operations)
    }

    public static async addPropertyToABankAccount({ bankAccountId, propertyIds }: { bankAccountId: number; propertyIds: number[] }) {
        await Api.put(`/nomenclature/bank-account/${bankAccountId}`, { properties: propertyIds })
    }
    public static async addNewLabel({ data }: { data: CreateNewLabelRequest }) {
        const response = await Api.post<UserDataDto>("/user/create-label", data)
        return {
            ...response,
            roles: response.roles
                ? {
                      ...response.roles,
                      from: fromIsoToDateTime(response.roles.from),
                      to: response.roles.to ? fromIsoToDateTime(response.roles.to) : undefined,
                  }
                : undefined,
        }
    }

    public static async addNewEvent(data: Partial<ICalendarEvent>) {
        if (!data.id) delete data.id
        await Api.post("/events", data)
    }

    public static async deleteEvent(id: string) {
        await Api.delete(`/events/${id}`)
    }

    public static async deleteInvoicingConfiguration(id: number) {
        await Api.delete(`/nomenclature/invoicing-configuration/${id}`)
    }

    public static async addIncome(data: AddIncomeRequest) {
        const formData = new FormData()
        formData.set("value", data.value.toString())
        formData.set("currencyId", data.currencyId.toString())
        formData.set("date", data.date)
        formData.set("paymentMethod", data.paymentMethod.toString())
        if (data.referenceId) formData.set("referenceId", data.referenceId.toString())
        data.files.forEach((f) => formData.append("files", f))

        const response = await Api.post<PropertyIncomeDto>("/income", formData)
        const parsed: PropertyIncome = {
            ...response,
            date: fromIsoToDateTime(response.date),
        }
        return parsed
    }

    public static async sendMessage(data: AddMessageRequest) {
        const formData = new FormData()
        formData.set("message", data.message.toString())
        data.files.forEach((f) => formData.append("files", f))
        await Api.post<never>("/user/contact", formData)
    }

    public static async saveUserAuthentication(email: string) {
        return Api.post<boolean>("/user/trigger-login", { userMail: email })
    }

    public static async checkOtp(email: string, token: string, notificationToken: string) {
        const response = await Api.post<UserDataDto>("/user/finish-login", { userMail: email, code: token, notificationToken })

        return {
            ...response,
            roles: response.roles
                ? {
                      ...response.roles,
                      from: fromIsoToDateTime(response.roles.from),
                      to: fromIsoToDateTime(response.roles.to),
                  }
                : undefined,
        }
    }

    public static async signUp(email: string, firstName: string, lastName: string) {
        return Api.post<string>("/user/signup", { userMail: email, firstName, lastName })
    }

    public static async getUserAvatar(userId: string) {
        return Api.getBlob(`/user/${userId}/profile-picture`)
    }

    public static async logout() {
        console.log("Logging out")
        growthbook?.setAttributes({ id: undefined, isTrial: false })

        return Api.get<string>("/user/logout")
    }

    public static async addPayment(data: AddPaymentRequest) {
        const formData = new FormData()
        formData.set("value", data.value.toString())
        formData.set("currencyId", data.currencyId.toString())
        formData.set("date", data.date)
        formData.set("paymentMethod", data.paymentMethod.toString())
        if (data.referenceId) formData.set("referenceId", data.referenceId.toString())
        data.files.forEach((f) => formData.append("files", f))

        const response = await Api.post<PropertyIncomeDto>("/income", formData)
        const parsed: PropertyIncome = {
            ...response,
            date: fromIsoToDateTime(response.date),
        }
        return parsed
    }

    public static async addCardPaymentData(data: AddCardPaymentDataRequest) {
        return await Api.put<AddCardPaymentDataRequest>("/user/card-payment-profile", data)
    }

    public static async addPropertyDocument(data: AddDocumentsRequest) {
        const formData = new FormData()
        formData.set("name", data.name.toString())
        if (data.rentingPeriodId && data.rentingPeriodId !== -1) formData.set("rentingPeriodId", data.rentingPeriodId.toString())
        if (data.notifyOtherParty) formData.set("notifyOtherParty", data.notifyOtherParty.toString())
        data.files.forEach((f) => formData.append("files", f))

        return Api.post(`/property/${data.propertyId}/files`, formData)
    }

    public static async uploadExpenseAIExtract(data: UploadExpenseExtractorAI): Promise<UploadExpenseExtractorAIResponse> {
        const formData = new FormData()
        data.files.forEach((f) => formData.append("files", f))

        const response = await Api.post<UploadExpenseExtractorAIResponseDto>("/expenses/ai-extractor", formData)

        return {
            ...response,
            date: response.date != null ? fromIsoToDateTime(response.date) : undefined,
            dueDate: response.dueDate != null ? fromIsoToDateTime(response.dueDate) : undefined,
        }
    }

    public static async addNewContract(data: ContractData): Promise<UserContractTemplate> {
        const response = await Api.post<UserContractTemplateDto>("/templates", data)

        return {
            ...response,
            createdAt: fromIsoToDateTime(response.createdAt),
        }
    }

    public static async handleArchiveContractTemplate(templateId: string, isArchived: boolean) {
        const response = await Api.put<UserContractTemplateDto>(`/templates/${templateId}/${isArchived ? "archive" : "unarchive"}`, {})

        return {
            ...response,
            createdAt: fromIsoToDateTime(response.createdAt),
        }
    }

    public static async addMeter({ data }: { data: AddMeterRequest }) {
        const formData = new FormData()
        formData.set("name", data.name.toString())
        formData.set("currentValue", data.currentValue.toString())
        formData.set("unitId", data.unitId.toString())
        formData.set("requirePhotoForReading", data.requirePhotoForReading.toString())
        formData.set("requireInteger", data.requireInteger.toString())
        formData.set("reportingPeriodStartDay", min(data.reportingPeriod)!.toString())
        formData.set("reportingPeriodEndDay", max(data.reportingPeriod)!.toString())
        formData.set("propertyId", data.propertyId.toString())
        if (data.files) {
            data.files.forEach((f) => formData.append("files", f))
        }

        const response = await Api.post<OwnerPropertyMeterDto>("/meters", formData)
        const parsed: OwnerPropertyMeter = {
            ...response,
            lastModified: fromIsoToDateTime(response.lastModified),
        }
        return parsed
    }

    public static async addMeterValue(data: AddMeterValueRequest) {
        const formData = new FormData()
        formData.set("value", data.value.toString())
        data.files?.forEach((f) => formData.append("files", f))

        let url = `/meters/${data.meterId}/values`
        if (data.rentingPeriodId) {
            url += `?rentingPeriodId=${data.rentingPeriodId}`
        }
        const response = await Api.post<PropertyMeterValueDto>(url, formData)
        const parsed: PropertyMeterValue = {
            ...response,
            createdAt: fromIsoToDateTime(response.createdAt),
            sentToProviderAt: response.sentToProviderAt ? fromIsoToDateTime(response.sentToProviderAt) : undefined,
            sendAutomaticallyAt: response.sendAutomaticallyAt ? fromIsoToDateTime(response.sendAutomaticallyAt) : undefined,
        }
        return parsed
    }

    public static async updateMeterValue({
        meterId,
        readingId,
        operations,
    }: {
        meterId: number
        readingId: number
        operations: jsonpatch.Operation[]
    }) {
        await Api.patch(`/meters/${meterId}/values/${readingId}`, operations)
    }

    public static async updateExpenseProvider({ providerId, operations }: { providerId: string; operations: jsonpatch.Operation[] }) {
        await Api.patch(`/expense-providers/${providerId}`, operations)
    }

    public static async sendMeterValue({ readingId, autoSend }: { readingId: number; autoSend: boolean }) {
        await Api.put(`/meters/${readingId}/send?autoSend=${autoSend}`, {})
    }

    public static async addUserInsurance(data: AddInsuranceRequest) {
        const formData = new FormData()
        formData.set("value", data.value.toString())
        formData.set("currencyId", data.currencyId.toString())
        formData.set("insurer", data.insurer)
        formData.set("startDate", data.startDate)
        formData.set("endDate", data.endDate)
        formData.set("insuranceType", data.insuranceType.toString())
        formData.set("propertyId", data.propertyId.toString())
        formData.set("shouldNotifyForRenewal", data.shouldNotifyForRenewal.toString())
        formData.set("shouldCreateExpense", data.shouldCreateExpense.toString())
        data.files.forEach((f) => formData.append("files", f))

        const response = await Api.post<PropertyInsuranceDto>("/insurance", formData)
        const parsed: PropertyInsurance = {
            ...response,
            startDate: fromIsoToDateTime(response.startDate),
            endDate: fromIsoToDateTime(response.endDate),
        }
        return parsed
    }

    public static async editMeter({ operations, meterId }: { operations: jsonpatch.Operation[]; meterId: number }) {
        const response = await Api.patch<OwnerPropertyMeterDto>(`/meters/${meterId}`, operations)
        const parsed: OwnerPropertyMeter = {
            ...response,
            lastModified: fromIsoToDateTime(response.lastModified),
        }
        return parsed
    }

    public static async updateUserData({ operations }: { operations: jsonpatch.Operation[] }) {
        await Api.patch<UserDataDto>("/user", operations)
    }

    public static async updateUserProfile(data: ProfileChangeRequest): Promise<never> {
        return Api.put<never>("/user/profile", data)
    }

    public static async updateUserImoCRMAgencyId(data: SyncImoCRMAgencyIdRequest | null): Promise<never> {
        return Api.post<never>("/user/agency", data ?? { agencyId: null })
    }

    public static async acceptPrivacyPolicy(): Promise<UserData> {
        const response = await Api.post<UserDataDto>("/user/acceptPrivacyPolicy", {})
        return {
            ...response,
            roles: {
                ...response.roles,
                from: fromIsoToDateTime(response.roles.from),
                to: fromIsoToDateTime(response.roles.to),
            },
        }
    }

    public static async acceptTOS(): Promise<UserData> {
        const response = await Api.post<UserDataDto>("/user/acceptTOS", {})
        return {
            ...response,
            roles: {
                ...response.roles,
                from: fromIsoToDateTime(response.roles.from),
                to: fromIsoToDateTime(response.roles.to),
            },
        }
    }

    public static async addSummaryCard(summaryCardId: number | undefined): Promise<{ id: number }> {
        return await Api.post<{ id: number }>("/summary-cards", { id: summaryCardId })
    }

    public static async addChart(chartId: number | undefined): Promise<{ id: number }> {
        return await Api.post<{ id: number }>("/charts", { id: chartId })
    }

    public static async addGroupSummaryCard(
        summaryCardId: number | undefined,
        groupId: number,
        dashboardConfigurationId: number,
    ): Promise<{ id: number }> {
        return await Api.post<{ id: number }>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigurationId}/summary-cards`, {
            id: summaryCardId,
        })
    }

    public static async addGroupChart(chartId: number | undefined, groupId: number, dashboardConfigurationId: number): Promise<{ id: number }> {
        return await Api.post<{ id: number }>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigurationId}/charts`, { id: chartId })
    }

    public static async generatePdfRequest(body: string) {
        return await Api.postGetBlob("/payment-notifications/preview", { body })
    }

    public static async generateGeneralDocumentPdfRequest(content: string) {
        return await Api.postGetBlob("/general-documents/preview", { body: content })
    }

    public static async generateGeneralDocumentPdfRequestConfiguration(documentId: number | undefined, variables: { [pattern: string]: string }) {
        return await Api.postGetBlob(`/general-documents/${documentId}/preview`, { variables })
    }

    public static async generateRentingPeriodPdfPReviewRequest(rentingPeriodId: number | undefined, notificationId: number) {
        return await Api.postGetBlob(`/tenants/${rentingPeriodId}/payment-notification/preview`, { notificationId })
    }

    public static async updateRentingPeriodNotificationId(rentingPeriodId: number | undefined, notificationId: number | null) {
        return await Api.put(`/tenants/${rentingPeriodId}/payment-notification`, { id: notificationId })
    }

    public static async getNotificationTemplateDetails({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { notificationId } = data as { notificationId: number }
        return await Api.get<NotificationTemplateDetails>(`/payment-notifications/${notificationId}`)
    }

    public static async getGeneralDocumentDetails({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { documentId } = data as { documentId: number }
        return await Api.get<GeneralDocumentDetails>(`/general-documents/${documentId}`)
    }

    public static async updateNotificationTemplateContent(notificationId: number, content: string | undefined) {
        return await Api.put(`/payment-notifications/${notificationId}/content`, { content })
    }

    public static async updateGeneralDocumentContent(documentId: number, content: string | undefined) {
        return await Api.put(`/general-documents/${documentId}/content`, { content })
    }

    public static async updateNotificationTemplate(notificationId: number, operations: jsonpatch.Operation[]) {
        return await Api.patch(`/payment-notifications/${notificationId}`, operations)
    }

    public static async updateGeneralDocument(documentId: number, operations: jsonpatch.Operation[]) {
        return await Api.patch(`/general-documents/${documentId}`, operations)
    }

    public static async updateNotificationTemplateVariable(rentingPeriodId: number, variable: NotificationTemplateVariable) {
        return await Api.put(`/tenants/${rentingPeriodId}/payment-notification/variable`, variable)
    }

    public static async updateNotificationTemplateDefaultVariable(notificationId: number, operations: jsonpatch.Operation[]) {
        return await Api.patch(`/payment-notifications/${notificationId}`, operations)
    }

    public static async updatePropertyDetails({ id, operations }: { id: number | undefined; operations: jsonpatch.Operation[] }) {
        if (id === undefined) throw new Error("Property id not specified")
        const isUpdatingLocation = operations.every((o) => o.path.startsWith("/location"))
        let url = ""
        if (isUpdatingLocation) {
            url = `/property/${id}/location`
        } else {
            url = `/property/${id}`
        }
        let data: jsonpatch.Operation[] | Record<string, string> = operations
        if (isUpdatingLocation) {
            data = {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lat: operations.find((o) => o.path === "/location/lat")?.value,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                lng: operations.find((o) => o.path === "/location/lng")?.value,
            }
        }
        return Api.patch<PropertyData>(url, data)
    }

    public static async deleteNotification(notificationId: number) {
        return Api.delete<never>(`/payment-notifications/${notificationId}`)
    }

    public static async deleteGeneralDocument(documentId: number) {
        return Api.delete<never>(`/general-documents/${documentId}`)
    }

    public static async deleteBankStatement(bankStatementId: number) {
        return Api.delete<never>(`/statements/${bankStatementId}`)
    }

    public static async deleteInvoicingConfigurationAccess(invoicingConfigurationId: number, userId: string) {
        return Api.delete<never>(`/nomenclature/invoicing-configuration/${invoicingConfigurationId}/users/${userId}`)
    }

    public static async deleteTenant({ tenantId, sendDataToOwner }: { tenantId: number; sendDataToOwner: boolean }) {
        return Api.delete<never>(`/tenants/${tenantId}?sendDataToOwner=${sendDataToOwner}`)
    }

    public static async deleteUserAccount() {
        return Api.delete<never>("/user")
    }
    public static async deleteLabel(labelId: number) {
        return Api.delete<never>(`/user/labels/${labelId}`)
    }

    public static async deleteAddress(addressId: string) {
        return Api.delete<never>(`/nomenclature/c168/${addressId}`)
    }
    public static async deleteCustomProvider(providerId: number) {
        return Api.delete<never>(`/nomenclature/providers/${providerId}`)
    }

    public static async deleteUserSection(sectionId: string) {
        return Api.delete<never>(`/tasks/sections/${sectionId}`)
    }
    public static async deleteSectionTask(sectionId: string | undefined, taskId: string) {
        return Api.delete<never>(`/tasks/sections/${sectionId}/tasks/${taskId}`)
    }

    public static async tenantDeleteTask(taskId: string, rentingPeriodId: number) {
        return Api.delete<never>(`/tasks/tenant/${rentingPeriodId}/tasks/${taskId}`)
    }

    public static async deleteTaskComment(sectionId: string | undefined, taskId: string, commentId: string) {
        return Api.delete<never>(`/tasks/sections/${sectionId}/tasks/${taskId}/comments/${commentId}`)
    }

    public static async deleteProperty(propertyId: number | undefined) {
        if (propertyId === undefined) throw new Error("Property id not specified")
        return Api.delete<never>(`/property/${propertyId}`)
    }

    public static async deleteExpense({ expenseId }: { expenseId: number }) {
        return Api.delete<never>(`/expenses/${expenseId}`)
    }

    public static async createInvoiceFromExpense({
        expenseId,
        invoiceDate,
        invoiceDueDate,
        modifyExpenseWithInvoiceData,
    }: {
        expenseId: number
        invoiceDate: string
        invoiceDueDate: string
        modifyExpenseWithInvoiceData: boolean
    }) {
        return Api.post<never>(`/expenses/${expenseId}/create-invoice`, { invoiceDate, invoiceDueDate, modifyExpenseWithInvoiceData })
    }

    public static async deleteRecurringExpense({ expenseId }: { expenseId: string }) {
        return Api.delete<never>(`/expenses/recurring/${expenseId}`)
    }

    public static async deleteSummaryCard({ summaryCardId }: { summaryCardId: number }) {
        return Api.delete<never>(`/summary-cards/${summaryCardId}`)
    }

    public static async deleteChart({ chartId }: { chartId: number }) {
        return Api.delete<never>(`/charts/${chartId}`)
    }

    public static async deleteGroupSummaryCard({
        groupId,
        summaryCardId,
        dashboardConfigId,
    }: {
        groupId: number
        summaryCardId: number
        dashboardConfigId: number
    }) {
        return Api.delete<never>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigId}/summary-cards/${summaryCardId}`)
    }

    public static async deleteGroupChart({ groupId, chartId, dashboardConfigId }: { groupId: number; chartId: number; dashboardConfigId: number }) {
        return Api.delete<never>(`/groups/${groupId}/dashboard-configurations/${dashboardConfigId}/charts/${chartId}`)
    }

    public static async updateRecurringExpense({ id, operations }: { id: string; operations: jsonpatch.Operation[] }) {
        const url = `/expenses/recurring/${id}`
        return Api.patch<never>(url, operations)
    }

    public static async deletePropertyIncome({ incomeId }: { incomeId: number }) {
        return Api.delete<never>(`/income/${incomeId}`)
    }

    public static async deleteMeter({ meterId }: { meterId: number }) {
        return Api.delete<never>(`/meters/${meterId}`)
    }

    public static async deleteMeterValue({ meterId, meterValueId }: { meterId: number; meterValueId: number }) {
        return Api.delete<never>(`/meters/${meterId}/values/${meterValueId}`)
    }

    public static async deleteContractTemplate({ contractTemplateId }: { contractTemplateId: string }) {
        return Api.delete<never>(`/templates/${contractTemplateId}`)
    }
    public static async deleteUserInsurance({ insuranceId }: { insuranceId: number }) {
        return Api.delete<never>(`/insurance/${insuranceId}`)
    }

    public static async deleteFile({ fileId }: { fileId: string }) {
        return Api.delete<never>(`/files/${fileId}`)
    }

    public static async deletePropertyPhoto({ fileId, propertyId }: { fileId: string; propertyId: number }) {
        return Api.delete<never>(`/property/${propertyId}/presentation-files/${fileId}`)
    }
    public static async deleteNote({ noteId }: { noteId: number }) {
        return Api.delete<never>(`/notes/${noteId}`)
    }

    public static async deleteRevision({ revisionId }: { revisionId: string }) {
        return Api.delete<never>(`/maintenance/${revisionId}`)
    }

    public static async deleteInventorySection({ inventorySectionId }: { inventorySectionId: number }) {
        return Api.delete<never>(`/inventory/sections/${inventorySectionId}`)
    }

    public static async deleteInventorySectionItem({ inventorySectionId, inventoryItemId }: { inventorySectionId: number; inventoryItemId: number }) {
        return Api.delete<never>(`/inventory/sections/${inventorySectionId}/items/${inventoryItemId}`)
    }

    public static async deleteObservation({ revisionId, observationId }: { revisionId: string; observationId: string }) {
        return Api.delete<never>(`/maintenance/${revisionId}/observations/${observationId}`)
    }

    public static async deleteGroup(groupId: number) {
        return Api.delete<never>(`/groups/${groupId}`)
    }

    public static async deleteBankAccount(bankAccountId: number) {
        return Api.delete<never>(`/nomenclature/bank-account/${bankAccountId}`)
    }

    public static async deletePropertyFromBankAccount(propertyId: number, bankAccountId: number) {
        return Api.delete<never>(`/nomenclature/bank-account/${bankAccountId}/remove-property/${propertyId}`)
    }

    public static async updateExpenseAsync({ expenseId, operations }: { expenseId: number; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<PropertyExpenseDto>(`/expenses/${expenseId}`, operations)
        const parsed: PropertyExpense = {
            ...response,
            date: fromIsoToDateTime(response.date),
            dueDate: fromIsoToDateTime(response.dueDate),
            dateAutomaticallySentToTenant: response.dateAutomaticallySentToTenant
                ? fromIsoToDateTime(response.dateAutomaticallySentToTenant)
                : undefined,
            providerInvoiceDate: response.providerInvoiceDate ? fromIsoToDateTime(response.providerInvoiceDate) : undefined,
            propertyIncomes: response.propertyIncomes.map((income) => ({ ...income, date: fromIsoToDateTime(income.date) })),
        }
        return parsed
    }

    public static async updateEventAsync({ eventId, operations }: { eventId: string; operations: jsonpatch.Operation[] }) {
        await Api.patch(`/events/${eventId}`, operations)
    }

    public static async updateLabelAsync({ labelId, operations }: { labelId: number; operations: jsonpatch.Operation[] }) {
        await Api.patch(`/user/labels/${labelId}`, operations)
    }

    public static async updateIncomeAsync({ incomeId, operations }: { incomeId: number; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<PropertyIncomeDto>(`/income/${incomeId}`, operations)
        const parsed: PropertyIncome = {
            ...response,
            date: fromIsoToDateTime(response.date),
        }
        return parsed
    }
    public static async updateRentingPeriod({ tenantId, operations }: { tenantId: number; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<RentingPeriodDto>(`/tenants/${tenantId}`, operations)
        const parsed: RentingPeriod = {
            ...response,
            moveInDate: fromIsoToDateTime(response.moveInDate),
            moveOutDate: fromIsoToDateTime(response.moveOutDate),
            acceptedDate: response.acceptedDate ? fromIsoToDateTime(response.acceptedDate) : undefined,
            invitedDate: response.invitedDate ? fromIsoToDateTime(response.invitedDate) : undefined,
            removeTenantAccessAt: response.removeTenantAccessAt ? fromIsoToDateTime(response.removeTenantAccessAt) : undefined,
        }
        return parsed
    }

    public static async updateInsuranceAsync({ insuranceId, operations }: { insuranceId: number; operations: jsonpatch.Operation[] }) {
        const response = await Api.patch<PropertyInsuranceDto>(`/insurance/${insuranceId}`, operations)
        const parsed: PropertyInsurance = {
            ...response,
            startDate: fromIsoToDateTime(response.startDate),
            endDate: fromIsoToDateTime(response.endDate),
        }
        return parsed
    }

    public static async getCoordinates({ queryKey }: QueryFunctionContext) {
        const [_, data] = queryKey
        const { city, county } = data as { city: string; county: string }

        return Api.get<{ lat: number; lng: number }>(`/geo/coordinates?CityId=${city ?? ""}&CountyId=${county ?? ""}`)
    }

    public static async changePassword(currentPassword: string, newPassword: string) {
        console.log("Change password - you are in Api ", currentPassword, newPassword)
    }

    public static async updateBackendPushNotificationsStatus(accept: boolean, token: string) {
        const url = `/user/notifications/${accept ? "accept" : "decline"}`
        await Api.put(url, { token })
    }

    public static async setRentingPeriodInvoicing(
        rentingPeriodId: number,
        type: "series" | "company" | "reinvoicing-series" | "reinvoicing-vat",
        id?: number,
    ) {
        const url = `/tenants/${rentingPeriodId}/set-invoicing-${type}`
        await Api.put(url, { id })
    }

    public static async createInvoice(rentingPeriodId: number, from: string, to: string, invoiceDate: string) {
        const url = `/tenants/${rentingPeriodId}/invoice`
        return await Api.postGetBlob(url, { from, to, invoiceDate })
    }

    public static async updateRentingPeriodNotification(tenantId: number, accept: boolean) {
        const url = `/tenants/${tenantId}/${accept ? "accept" : "decline"}`
        await Api.post(url, {})
    }

    public static async updateManagerNotification(propertyId: number, accept: boolean) {
        const url = `/property/${propertyId}/${accept ? "accept" : "decline"}`
        await Api.post(url, {})
    }

    public static async tenantWithContractAcceptRent(data: TenantWithContract) {
        const formData = new FormData()
        if (data.tenantSignature) formData.set("tenantSignature", data.tenantSignature)
        formData.set("contract", data.contract, `contract-${DateTime.now().toLocaleString()}.pdf`)

        if (data.tenantContactInfo?.type) formData.set("tenantContactInfo.type", data.tenantContactInfo.type.toString())
        if (data.tenantContactInfo?.contactInfoPersonType)
            formData.set("tenantContactInfo.contactInfoPersonType", data.tenantContactInfo.contactInfoPersonType.toString())
        if (data.tenantContactInfo?.companyName) formData.set("tenantContactInfo.companyName", data.tenantContactInfo.companyName.toString())
        if (data.tenantContactInfo?.companyRegistrationNumber)
            formData.set("tenantContactInfo.companyRegistrationNumber", data.tenantContactInfo.companyRegistrationNumber.toString())
        if (data.tenantContactInfo?.companyPosition)
            formData.set("tenantContactInfo.companyPosition", data.tenantContactInfo.companyPosition.toString())
        if (data.tenantContactInfo?.fullName) formData.set("tenantContactInfo.fullName", data.tenantContactInfo.fullName.toString())
        if (data.tenantContactInfo?.city) formData.set("tenantContactInfo.city", data.tenantContactInfo.city.toString())
        if (data.tenantContactInfo?.county) formData.set("tenantContactInfo.county", data.tenantContactInfo.county.toString())
        if (data.tenantContactInfo?.street) formData.set("tenantContactInfo.street", data.tenantContactInfo.street.toString())
        if (data.tenantContactInfo?.streetNumber) formData.set("tenantContactInfo.streetNumber", data.tenantContactInfo.streetNumber.toString())
        if (data.tenantContactInfo?.buildingNumber) formData.set("tenantContactInfo.buildingNumber", data.tenantContactInfo.buildingNumber.toString())
        if (data.tenantContactInfo?.stair) formData.set("tenantContactInfo.stair", data.tenantContactInfo.stair.toString())
        if (data.tenantContactInfo?.floor) formData.set("tenantContactInfo.floor", data.tenantContactInfo.floor.toString())
        if (data.tenantContactInfo?.apartment) formData.set("tenantContactInfo.apartment", data.tenantContactInfo.apartment.toString())
        if (data.tenantContactInfo?.identitySeries) formData.set("tenantContactInfo.identitySeries", data.tenantContactInfo.identitySeries.toString())
        if (data.tenantContactInfo?.identityNumber) formData.set("tenantContactInfo.identityNumber", data.tenantContactInfo.identityNumber.toString())
        if (data.tenantContactInfo?.phone) formData.set("tenantContactInfo.phone", data.tenantContactInfo.phone.toString())

        return Api.post<unknown>(`/tenants/${data.tenantId}/accept`, formData)
    }

    public static async healthCheck() {
        return Api.get<{ azure: HealthCheck; database: HealthCheck; graylog: HealthCheck }>("/health/check")
    }

    private static async get<T>(path: string, buildConfig = true) {
        const response = await axios.get(`${this.API}${path}`, buildConfig ? Api.buildConfig() : undefined)
        return response.data as T
    }

    private static async patch<T>(path: string, data: unknown, buildConfig = true) {
        const response = await axios.patch(`${this.API}${path}`, data, buildConfig ? Api.buildConfig() : undefined)
        return response.data as T
    }

    private static async post<T>(path: string, data: unknown, buildConfig = true) {
        const response = await axios.post(`${this.API}${path}`, data, buildConfig ? Api.buildConfig() : undefined)
        return response.data as T
    }

    private static async put<T>(path: string, data: unknown, buildConfig = true) {
        const response = await axios.put(`${this.API}${path}`, data, buildConfig ? Api.buildConfig() : undefined)
        return response.data as T
    }

    private static async delete<T>(path: string, buildConfig = true) {
        const response = await axios.delete(`${this.API}${path}`, buildConfig ? Api.buildConfig() : undefined)
        return response.data as T
    }
    private static async getBlob(path: string, buildConfig = true) {
        const response = await axios.get(
            `${this.API}${path}`,
            buildConfig
                ? {
                      ...Api.buildConfig(),
                      responseType: "blob",
                  }
                : undefined,
        )
        return response.data
    }

    private static async postGetBlob(path: string, data: any, buildConfig = true) {
        const response = await axios.post(
            `${this.API}${path}`,
            data,
            buildConfig
                ? {
                      ...Api.buildConfig(),
                      responseType: "blob",
                  }
                : undefined,
        )
        return response.data
    }

    private static async postGetBlobC168(path: string, data: any, buildConfig = true) {
        const response = await axios.post(
            `https://api.c168.ro${path}`,
            data,
            buildConfig
                ? {
                      ...Api.buildConfig(),
                      responseType: "blob",
                  }
                : undefined,
        )
        return response.data
    }

    private static buildConfig() {
        return {
            withCredentials: true,
            headers: {
                "X-CONTEXT": `${Api.CONTEXT}`,
                "X-LANGUAGE": i18n.language,
            },
        }
    }
}
