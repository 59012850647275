import { useSelector } from "../redux/store"
import { useMemo } from "react"
import { AppContext } from "rentzz"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { useTenantRentingPeriodsQuery, useUserDataQueryFn, useUserPropertiesQuery } from "../queries/userData"

export enum PermissionType {
    Expenses,
    RecurringExpense,
    PendingExpenses,
    Calendar,
    Incomes,
    Files,
    Documents,
    Insurances,
    RentingPeriods,
    Meters,
    Properties,
    Notes,
    Tasks,
    Revisions,
    BankAccounts,
    InventorySections,
    InventoryItems,
}

export const usePermissions = (type?: PermissionType, propertyId?: number) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: rentingPeriods } = useTenantRentingPeriodsQuery()
    const { data: userData } = useUserDataQueryFn()

    const canRead = useMemo(() => {
        return false
    }, [])

    const canWrite = useMemo(() => {
        if (context === AppContext.Tenant && [PermissionType.Expenses, PermissionType.RecurringExpense]) return false
        if (context === AppContext.Owner && userData?.roles == null) {
            return false
        }

        const property = propertyId ?? currentPropertyId
        if (property != null)
            return userProperties?.some((p) => [ManagerType.FULL, ManagerType.READ_WRITE].includes(p.ownershipType) && p.id === property)
        if (userProperties?.every((p) => p.createdByMe === false)) return true

        return userProperties?.some((p) => [ManagerType.FULL, ManagerType.READ_WRITE].includes(p.ownershipType))
    }, [userProperties, currentPropertyId, propertyId, context, userData])

    const editableProperties = useMemo(() => {
        if (context === AppContext.Owner && userData?.roles == null) {
            return []
        }
        return userProperties?.filter((p) => [ManagerType.FULL, ManagerType.READ_WRITE].includes(p!.ownershipType))
    }, [userProperties, context, userData])

    const editablePropertiesIds = useMemo(() => {
        if (context === AppContext.Tenant) {
            return rentingPeriods?.map((rp) => rp.propertyId)
        }
        if (context === AppContext.Owner && userData?.roles == null) {
            return []
        }

        return userProperties?.filter((p) => [ManagerType.FULL, ManagerType.READ_WRITE].includes(p!.ownershipType)).map((p) => p.id)
    }, [userProperties, rentingPeriods, context, userData])

    const isPropertyEditable = useMemo(
        () =>
            userProperties?.some(
                (p) => [ManagerType.FULL, ManagerType.READ_WRITE].includes(p!.ownershipType) && p.id === (propertyId ?? currentPropertyId),
            ) && userData?.roles != null,
        [userProperties, propertyId, userData, currentPropertyId],
    )

    const canAddManager = useMemo(
        () =>
            userProperties?.some((p) => [ManagerType.FULL].includes(p!.ownershipType) && p.id === (propertyId ?? currentPropertyId)) &&
            userData?.roles != null,
        [userProperties, propertyId, userData, currentPropertyId],
    )

    const canDelete = useMemo(
        () =>
            userProperties?.some(
                (p) => [ManagerType.FULL, ManagerType.READ_WRITE].includes(p!.ownershipType) && p.id === (propertyId ?? currentPropertyId),
            ),
        [userProperties, propertyId, currentPropertyId],
    )

    const deletablePropertiesIds = useMemo(() => {
        return userProperties?.filter((p) => [ManagerType.FULL, ManagerType.READ_WRITE].includes(p!.ownershipType)).map((p) => p.id)
    }, [userProperties, rentingPeriods, context, userData])

    return useMemo(
        () => ({
            canWrite,
            canRead,
            editableProperties,
            editablePropertiesIds,
            isPropertyEditable,
            canAddManager,
            canDelete,
            deletablePropertiesIds,
        }),
        [canRead, canWrite, editableProperties, editablePropertiesIds],
    )
}
