import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomDialog } from "rentzz"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import * as React from "react"
import { useEffect, useMemo, useRef } from "react"
import {
    Annotation,
    BookmarkView,
    Inject,
    LinkAnnotation,
    Magnification,
    Navigation,
    PdfViewerComponent,
    Print,
    TextSelection,
    ThumbnailView,
    Toolbar,
    ToolbarSettingsModel,
} from "@syncfusion/ej2-react-pdfviewer"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../queries/userData"
import { blobToBase64 } from "../../../../utils/actions"

export default function GeneralDocumentPreview() {
    const dispatch = useDispatch()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const pdfRef = useRef<PdfViewerComponent>(null)
    const backendPath = useFeatureValue(UserFlags.BackendPath, "")
    const toolbarItems = useMemo((): ToolbarSettingsModel => {
        return {
            toolbarItems: ["PageNavigationTool", "MagnificationTool", "DownloadOption", "PrintOption"],
        }
    }, [])

    useEffect(() => {
        if (editingItem?.pdfUrl) {
            fetch(editingItem.pdfUrl)
                .then((res) => res.blob())
                .then((blob) => blobToBase64(blob))
                .then((r) => {
                    pdfRef.current?.load(r, "")
                })
        } else {
            pdfRef.current?.unload()
        }
    }, [editingItem])

    return (
        <CustomDialog
            open={modalOpen === AppModals.GeneralDocumentPreview || modalOpen === AppModals.NotificationPreview}
            isFullScreen={true}
            onClose={() => {
                dispatch(setModalOpen(undefined))
            }}
        >
            <PdfViewerComponent
                ref={pdfRef}
                toolbarSettings={toolbarItems}
                style={{ height: "100%" }}
                serviceUrl={`${backendPath}/pdf-viewer`}
                ajaxRequestSettings={{
                    ajaxHeaders: [],
                    withCredentials: true,
                }}
            >
                <Inject
                    services={[Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection]}
                />
            </PdfViewerComponent>
        </CustomDialog>
    )
}
