import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { Box, Card, CardContent, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useCallback, useMemo, useState } from "react"
import { InventoryItem, InventorySection, PropertyFileType } from "../../../redux/slices/AddProperty"
import { CustomDataGrid, useLocales } from "rentzz"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { useDispatch, useSelector } from "../../../redux/store"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { useInventorySectionItemsQueryFn } from "../../../queries/inventory"
import NoDataPage from "../../../components/NoDataPage"
import Header from "../../expenses/expense-details/Header"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { DateTime } from "luxon"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import DeleteIcon from "@mui/icons-material/Delete"

export interface DetailsPanelProps {
    currentInventorySection: InventorySection & { canWrite: boolean; canDelete: boolean }
}
const InventorySectionDetailsPanel = ({ currentInventorySection }: DetailsPanelProps) => {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()
    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "createdAt", sort: "desc" }])
    const theme = useTheme()
    const { context } = useSelector((state) => state.appState)
    const { data: inventoryItems, isFetching } = useInventorySectionItemsQueryFn(
        currentInventorySection.id,
        paginationModel.page,
        paginationModel.pageSize,
        sortModel,
    )

    const columns = useMemo(() => {
        const columns: GridColDef<InventoryItem>[] = [
            {
                field: "itemName",
                type: "string",
                flex: 0.5,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("inventory_item_name"),
            },
            {
                field: "itemDescription",
                minWidth: 90,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("inventory_item_description"),
                renderCell: (v) =>
                    ActionWithTooltip({
                        element: <Typography fontSize={14}>{v.value.substring(0, 25)}</Typography>,
                        tooltipText: v.value.length > 25 ? v.value : "",
                    }),
            },
            {
                field: "status",
                type: "number",
                width: 130,
                headerAlign: "center",
                align: "center",
                headerName: translate("inventory_item_status"),
                filterable: true,
                valueFormatter: (value) => translate(`inventory-item-status-${value}`),
            },
            {
                field: "purchaseValue",
                type: "number",
                width: 170,
                headerAlign: "center",
                align: "center",
                headerName: translate("purchase_price"),
                filterable: true,
                valueFormatter: (value, row) => (value != null ? getAmount(value, row.currencyId) : "-"),
            },
            {
                field: "purchaseDate",
                type: "date",
                width: 130,
                headerAlign: "center",
                align: "center",
                headerName: translate("purchase_date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            width: 180,
            type: "actions",
            disableReorder: true,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams<InventoryItem>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.EditInventorySectionItem))
                                dispatch(
                                    setEditingItem({
                                        inventorySectionId: params.row.inventorySectionId,
                                        inventoryItemId: params.row.id,
                                    }),
                                )
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        inventoryItemId: params.id,
                                        type: PropertyFileType.InventoryItem,
                                        name: params.row.itemName,
                                        canWrite: currentInventorySection.canWrite,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteInventorySectionItem))
                                dispatch(setEditingItem({ inventorySectionId: currentInventorySection.id, inventoryItemId: params.row.id }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    key: "delete",
                    tooltipText: "",
                    hidden: !currentInventorySection.canDelete,
                }),
            ],
        }),
        [translate, dispatch, context, currentInventorySection],
    )

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                    paddingBottom: 3,
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    <Header currentItem={currentInventorySection} itemType='inventorySection' />

                    <CustomDataGrid
                        paginationMode={"server"}
                        isLoading={isFetching}
                        columns={columns}
                        rows={inventoryItems?.items ?? []}
                        components={{
                            noRowsOverlay: () => (
                                <NoDataPage onAddNewItem={undefined} icon={"mdi:bookshelf"} noDataText={"no_inventory_section_items"} />
                            ),
                        }}
                        idKey={"id"}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        sortingMode='server'
                        totalCount={inventoryItems?.count}
                        actionsColumn={[actionsColumn]}
                        onPaginationModelChange={setPaginationModel}
                        getRowId={(row) => row.id}
                        gridId='DesktopInventoryDetailsList'
                    />
                </CardContent>
            </Card>
        </Box>
    )
}

export default InventorySectionDetailsPanel
