import React, { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../redux/slices/App"
import { Box } from "@mui/material"
import {
    Annotation,
    BookmarkView,
    Inject,
    LinkAnnotation,
    Magnification,
    Navigation,
    PdfViewerComponent,
    Print,
    TextSearch,
    TextSelection,
    ThumbnailView,
    Toolbar,
    ToolbarSettingsModel,
} from "@syncfusion/ej2-react-pdfviewer"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../queries/userData"

const blobToBase64 = (blob: Blob): Promise<string> => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
    })
}

export default function PDFViewer() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const backendPath = useFeatureValue(UserFlags.BackendPath, "")
    const pdfRef = useRef<PdfViewerComponent>(null)
    const mobileRef = useRef<PdfViewerComponent>(null)

    useEffect(() => {
        if (editingItem?.url) {
            fetch(editingItem.url)
                .then((res) => res.blob())
                .then((blob) => blobToBase64(blob))
                .then((r) => {
                    if (isMobile) {
                        mobileRef.current?.load(r, "")
                    } else {
                        pdfRef.current?.load(r, "")
                    }
                })
        } else {
            pdfRef.current?.unload()
            mobileRef.current?.unload()
        }
    }, [editingItem])

    const toolbarItems = useMemo((): ToolbarSettingsModel => {
        if (isMobile) {
            return {
                toolbarItems: ["DownloadOption", "PrintOption"],
            }
        }
        return {
            toolbarItems: ["PageNavigationTool", "MagnificationTool", "DownloadOption", "PrintOption"],
        }
    }, [isMobile])

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.PdfViewer}
                isFullScreen={isMobile}
                maxWidth={"md"}
                title={""}
                onClose={() => {
                    dispatch(setModalOpen(editingItem?.previousModal))
                }}
            >
                <Box height={750}>
                    <PdfViewerComponent
                        ref={pdfRef}
                        toolbarSettings={toolbarItems}
                        style={{ height: 750 }}
                        serviceUrl={`${backendPath}/pdf-viewer`}
                        ajaxRequestSettings={{
                            ajaxHeaders: [],
                            withCredentials: true,
                        }}
                    >
                        <Inject
                            services={[
                                Toolbar,
                                Magnification,
                                Navigation,
                                Annotation,
                                LinkAnnotation,
                                BookmarkView,
                                ThumbnailView,
                                Print,
                                TextSelection,
                            ]}
                        />
                    </PdfViewerComponent>
                </Box>
            </CustomDialog>
            <DetailsSheet
                isLoading={false}
                title={translate("preview")}
                isOpen={isMobile && modalOpen === AppModals.PdfViewer}
                onClose={() => dispatch(setModalOpen(editingItem?.previousModal))}
            >
                <Box height={"75vh"}>
                    <PdfViewerComponent
                        ref={mobileRef}
                        style={{ height: "75vh" }}
                        serviceUrl={`${backendPath}/pdf-viewer`}
                        toolbarSettings={toolbarItems}
                        enableTextSelection={false}
                        ajaxRequestSettings={{
                            ajaxHeaders: [],
                            withCredentials: true,
                        }}
                    >
                        <Inject
                            services={[
                                Toolbar,
                                Magnification,
                                Navigation,
                                Annotation,
                                LinkAnnotation,
                                BookmarkView,
                                ThumbnailView,
                                Print,
                                TextSelection,
                                TextSearch,
                            ]}
                        />
                    </PdfViewerComponent>
                </Box>
            </DetailsSheet>
        </>
    )
}
