import AddOrEditInventorySectionGuard from "./AddOrEditInventorySectionGuard"
import DeleteInventorySectionGuard from "./DeleteInventorySectionGuard"
import InventorySectionDetailsSheet from "../../../sections/propertyDetails/inventory/InventorySectionDetailsSheet"
import AddOrEditInventorySectionItemGuard from "./AddOrEditInventorySectionItemGuard"
import DeleteInventorySectionItemGuard from "./DeleteInventorySectionItemGuard"
import MobileInventoryItemsListDetailsSheet from "../../../sections/propertyDetails/inventory/MobileInventoryItemsListDetailsSheet"
import MobileInventorySectionItemDetailsSheet from "../../../sections/propertyDetails/inventory/MobileInventorySectionItemDetailsSheet"

export default function InventoryGuard() {
    return (
        <>
            <AddOrEditInventorySectionGuard />
            <AddOrEditInventorySectionItemGuard />
            <DeleteInventorySectionGuard />
            <InventorySectionDetailsSheet />
            <DeleteInventorySectionItemGuard />
            <MobileInventoryItemsListDetailsSheet />
            <MobileInventorySectionItemDetailsSheet />
        </>
    )
}
