import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"
import { AddInventorySectionRequest } from "../sections/propertyDetails/inventory/AddOrEditInventorySectionForm"
import { AddInventorySectionItemRequest } from "../sections/propertyDetails/inventory/AddOrEditInventorySectionItemForm"

export const addInventorySectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddInventorySectionRequest }) => Api.addInventorySection(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const addInventorySectionItemMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data, inventorySectionId }: { data: AddInventorySectionItemRequest; inventorySectionId: number }) =>
            Api.addInventorySectionItem(data, inventorySectionId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySectionItems, ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const deleteInventorySectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ inventorySectionId }: { inventorySectionId: number }) => Api.deleteInventorySection({ inventorySectionId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const deleteInventorySectionItemMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ inventorySectionId, inventoryItemId }: { inventorySectionId: number; inventoryItemId: number }) =>
            Api.deleteInventorySectionItem({ inventorySectionId, inventoryItemId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections, ComplexQueryIDs.InventorySectionItems])
            }
        },
    })
}

export const updateInventorySectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ inventorySectionId, operations }: { inventorySectionId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateInventorySection({
                inventorySectionId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const updateInventorySectionItemMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            inventorySectionId,
            inventoryItemId,
            operations,
        }: {
            inventorySectionId: number
            inventoryItemId: number
            operations: jsonpatch.Operation[]
        }) =>
            Api.updateInventorySectionItem({
                inventorySectionId,
                inventoryItemId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySectionItems])
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}
