import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import Api from "../api/Api"
import { AppContext, PromoCodeRequest, useIsMobile, useLocales } from "rentzz"
import * as jsonpatch from "fast-json-patch"
import { AccountType, DashboardChartType, DashboardEntryType, SummaryCardType, UserData, UserFlags } from "../queries/userData"
import { useCurrencyQueryFn } from "../queries/currency"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { TenantWithContract } from "../sections/tenantContext/rentingPeriodNotification/wizard/RentingPeriodWizard"
import { ProfileChangeRequest } from "../sections/settings/general/GeneralProfile"
import { DateTime } from "luxon"
import { AddMessageRequest } from "../guards/alertsGuard/contact/types"
import { useNavigate } from "react-router-dom"
import { setContext, setCurrentPropertyId, setCurrentRentingPeriodId, setErrorMessage } from "../redux/slices/App"
import { AxiosError } from "axios"
import { useDispatch, useSelector } from "../redux/store"
import { useFeatureValue, useGrowthBook } from "@growthbook/growthbook-react"
import { getToken } from "firebase/messaging"
import { messaging } from ".."
import { CreateNewProviderRequest } from "../sections/nomenclature/providers/AddOrEditNewProvider"
import { BackendError } from "../sections/types/user"
import { AddBankAccountRequest } from "../sections/nomenclature/bankAccounts/AddBankAccountForm"
import { AddCardPaymentDataRequest } from "../sections/expenses/online-payment/AddCardPaymentDataForm"
import { useSetAtom } from "jotai/index"
import { DashboardPages, dashboardTableAtom } from "../utils/atom"
import { SyncImoCRMAgencyIdRequest } from "../pages/properties/SyncPropertiesForm"

export const updatePassword = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()

    return useMutation({
        mutationFn: ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) =>
            Api.changePassword(currentPassword, newPassword),
        onSuccess: () => {
            enqueueSnackbar(translate("updated"), { variant: "success" })
        },
    })
}

export const updateBackendPushNotificationsStatus = () => {
    const vapidKey = useFeatureValue(UserFlags.VAPID_KEY, "")

    return useMutation({
        mutationFn: async ({ notificationsActive }: { notificationsActive: boolean }) => {
            let token = ""
            const messagingInstance = await messaging()
            if (notificationsActive && messagingInstance) {
                try {
                    token = await getToken(messagingInstance, {
                        vapidKey: vapidKey,
                    })
                    return Api.updateBackendPushNotificationsStatus(notificationsActive, token)
                } catch (ex) {
                    console.log(ex)
                }
            }
        },
    })
}

export const updateRentingPeriod = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ tenantId, accept }: { tenantId: number; accept: boolean }) => Api.updateRentingPeriodNotification(tenantId, accept),
        onSuccess: () => {
            enqueueSnackbar(translate("updated"), { variant: "success" })
        },
        onSettled: async () => {
            await invalidateQueries([
                SimpleQueryIDs.UserData,
                SimpleQueryIDs.TenantRequests,
                SimpleQueryIDs.UserProperties,
                SimpleQueryIDs.TenantRentingPeriods,
            ])
        },
    })
}

export const updateManagerMutation = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()
    const { invalidateQueries } = useQueryInvalidator()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: ({ propertyId, accept }: { propertyId: number; accept: boolean }) => Api.updateManagerNotification(propertyId, accept),
        onSuccess: () => {
            enqueueSnackbar(translate("updated"), { variant: "success" })
        },
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (_, error) => {
            if (isMobile && error) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
            await invalidateQueries([SimpleQueryIDs.UserData, SimpleQueryIDs.ManagerRequest, SimpleQueryIDs.UserProperties])
        },
    })
}

export const updateRentingPeriodWithContractMutation = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: TenantWithContract) => Api.tenantWithContractAcceptRent(data),
        onSuccess: () => {
            enqueueSnackbar(translate("updated"), { variant: "success" })
        },
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData, SimpleQueryIDs.TenantRequests, SimpleQueryIDs.TenantRentingPeriods])
            }
        },
    })
}

export const updateUserCurrency = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { data: currencies } = useCurrencyQueryFn()

    return useMutation({
        mutationFn: ({ operations }: { operations: jsonpatch.Operation[] }) => Api.updateUserData({ operations }),
        onMutate: async ({ operations }) => {
            await queryClient.cancelQueries({ queryKey: [SimpleQueryIDs.UserData] })
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData): UserData => {
                const converted = oldData as UserData
                const ourObject = jsonpatch.deepClone(converted)
                const newCurrency = currencies?.find((c) => c.id === (operations[0] as jsonpatch.ReplaceOperation<number>).value)
                return {
                    ...ourObject,
                    currency: newCurrency,
                    roles: {
                        from: DateTime.fromISO(ourObject.roles.from),
                        to: DateTime.fromISO(ourObject.roles.to),
                    },
                }
            })

            return { previousData }
        },
        onSettled: async (data, error, _, context) => {
            if (error) queryClient.setQueryData([SimpleQueryIDs.UserData], context?.previousData)
            await invalidateQueries([
                SimpleQueryIDs.UserData,
                ComplexQueryIDs.SummaryCard,
                ComplexQueryIDs.DashboardChart,
                ComplexQueryIDs.DashboardTable,
                ComplexQueryIDs.Property,
            ])
        },
    })
}

export const updateUserLanguage = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    return useMutation({
        mutationFn: ({ operations }: { operations: jsonpatch.Operation[] }) => Api.updateUserData({ operations }),
        onMutate: async ({ operations }) => {
            await queryClient.cancelQueries({ queryKey: [SimpleQueryIDs.UserData] })
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData) => {
                const converted = oldData as UserData
                return {
                    ...jsonpatch.applyPatch(
                        converted,
                        operations.map((o) => ({ ...o, path: o.path.replace("/userPreferences", "") })),
                    ).newDocument,
                }
            })

            return { previousData }
        },
        onSettled: async (data, error, _, context) => {
            if (error) queryClient.setQueryData([SimpleQueryIDs.UserData], context?.previousData)
            await invalidateQueries([SimpleQueryIDs.UserData])
        },
    })
}

export const acceptPrivacyPolicyMutation = (invalidate = true) => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: () => Api.acceptPrivacyPolicy(),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [SimpleQueryIDs.UserData] })
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            if (invalidate) {
                queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData): UserData => {
                    const converted = oldData as UserData
                    return {
                        ...converted,
                        showPrivacyPolicy: false,
                    }
                })
            }

            return { previousData }
        },
        onSettled: async (data, error, _, context) => {
            if (error) queryClient.setQueryData([SimpleQueryIDs.UserData], context?.previousData)
            if (invalidate) await invalidateQueries([SimpleQueryIDs.UserData])
        },
    })
}

export const acceptTOSMutation = (invalidate = true) => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: () => Api.acceptTOS(),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [SimpleQueryIDs.UserData] })
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            if (invalidate) {
                queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData): UserData => {
                    const converted = oldData as UserData
                    return {
                        ...converted,
                        showTOS: false,
                    }
                })
            }

            return { previousData }
        },
        onSettled: async (data, error, _, context) => {
            if (error) queryClient.setQueryData([SimpleQueryIDs.UserData], context?.previousData)
            if (invalidate) await invalidateQueries([SimpleQueryIDs.UserData])
        },
    })
}

export const updateUserProfile = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: ProfileChangeRequest) => Api.updateUserProfile(data),
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserData, SimpleQueryIDs.C168Addresses])
        },
    })
}

export const updateUserImoCRMAgencyIdMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: SyncImoCRMAgencyIdRequest | null) => Api.updateUserImoCRMAgencyId(data),
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserData])
        },
    })
}

export const getSubscriptionCheckoutLinkMutation = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()

    return useMutation({
        mutationFn: ({ id, promotionalCode }: { id: string; promotionalCode?: string }) => Api.getSubscriptionCheckoutLink(id, promotionalCode),
        onSuccess: (data) => {
            if (data === "") enqueueSnackbar(translate("get_subscription_plan"), { variant: "success" })
        },
    })
}
export const checkCodeMutation = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()

    return useMutation({
        mutationFn: (data: PromoCodeRequest) => Api.getPromoCodeDetails(data),
        onSuccess: () => {
            enqueueSnackbar(translate("updated"), { variant: "success" })
        },
    })
}

export const sendMessageMutation = () => {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: (data: AddMessageRequest) => Api.sendMessage(data),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: (_, error) => {
            if (isMobile && error) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}

export const saveUserAuthentication = () => {
    return useMutation({ mutationFn: (email: string) => Api.saveUserAuthentication(email) })
}

export const checkOtp = () => {
    const queryClient = useQueryClient()
    const growthBook = useGrowthBook()
    const vapidKey = useFeatureValue(UserFlags.VAPID_KEY, "")
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: async ({ email, otp }: { email: string; otp: string }) => {
            let token = ""
            const messagingInstance = await messaging()
            if (window.Notification?.permission === "granted" && messagingInstance) {
                token = await getToken(messagingInstance, {
                    vapidKey: vapidKey,
                })
            }

            return Api.checkOtp(email, otp, token)
        },
        onSuccess: (data) => {
            const lastUserId = localStorage.getItem("lastUserId")
            if (lastUserId == null) {
                localStorage.setItem("lastUserId", data.id)
            } else {
                if (lastUserId !== data.id) {
                    dispatch(setCurrentPropertyId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                    dispatch(setContext(undefined))
                }
            }
            queryClient.setQueryData([SimpleQueryIDs.UserData], data)
            growthBook?.setAttributes({ id: data?.id, isTrial: data?.roles?.isTrial ?? false })
        },
        onError: () => {},
    })
}

export const signUp = () => {
    return useMutation({
        mutationFn: ({ email, lastName, firstName }: { email: string; lastName: string; firstName: string }) =>
            Api.signUp(email, firstName, lastName),
    })
}

export const useLogout = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const setDashboardPage = useSetAtom(dashboardTableAtom)

    return useMutation({
        mutationFn: async () => {
            setDashboardPage(DashboardPages.General)
            await Api.logout()
            queryClient.removeQueries()
            navigate("/dashboard")
        },
    })
}

export const deleteUserAccountMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteUserAccount,
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserData])
        },
    })
}

export const createNewProviderMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: CreateNewProviderRequest) => Api.createNewProvider({ data }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserProviders])
            }
        },
    })
}

export const updateCustomProvidersMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ providerId, operations }: { providerId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateCustomProviders({
                providerId,
                operations,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserProviders])
            }
        },
    })
}

export const deleteCustomProviderMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteCustomProvider,
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserProviders])
        },
    })
}

export const blockUserMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ userId }: { userId: string }) => Api.blockUser({ userId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.ActiveUsers, ComplexQueryIDs.ActiveUser])
            }
        },
    })
}

export const updateSendCodeViaSMSMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ userId, phoneNumber, isRequired }: { userId: string; phoneNumber?: string; isRequired: boolean }) =>
            Api.updateSendCodeViaSMS({ userId, phoneNumber, isRequired }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.ActiveUsers])
                await invalidateQueries([ComplexQueryIDs.ActiveUser])
            }
        },
    })
}

export const addSummaryCardMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    return useMutation({
        mutationFn: ({ summaryCardId }: { summaryCardId: number | undefined }) => Api.addSummaryCard(summaryCardId),
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData, ComplexQueryIDs.SummaryCard])
            }
        },
    })
}

export const addChartMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    return useMutation({
        mutationFn: ({ chartId }: { chartId: number | undefined }) => Api.addChart(chartId),
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData, ComplexQueryIDs.DashboardChart])
            }
        },
    })
}

export const deleteSummaryCardMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { context } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ summaryCardId }: { summaryCardId: number; summaryCards: SummaryCardType[] }) => Api.deleteSummaryCard({ summaryCardId }),
        onMutate: ({ summaryCards }) => {
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            const differentContextCards = (previousData as UserData & { summaryCards: SummaryCardType[] }).summaryCards.filter(
                (c) => c.entryType !== (context === AppContext.Owner ? DashboardEntryType.Owner : DashboardEntryType.Tenant),
            )

            queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData): UserData => {
                const converted = oldData as UserData
                return {
                    ...converted,
                    summaryCards: [...differentContextCards, ...summaryCards],
                }
            })
        },
        onSettled: (_, error) => {
            if (error) {
                invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export const deleteChartMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { context } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ chartId }: { chartId: number; charts: DashboardChartType[] }) => Api.deleteChart({ chartId }),
        onMutate: ({ charts }) => {
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            const differentContextCards = (previousData as UserData & { charts: DashboardChartType[] }).dashboardCharts.filter(
                (c) => c.entryType !== (context === AppContext.Owner ? DashboardEntryType.Owner : DashboardEntryType.Tenant),
            )

            queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData): UserData => {
                const converted = oldData as UserData
                return {
                    ...converted,
                    dashboardCharts: [...differentContextCards, ...charts],
                }
            })
        },
        onSettled: (_, error) => {
            if (error) {
                invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export const updateSummaryCardMutation = () => {
    const queryClient = useQueryClient()
    const { context } = useSelector((state) => state.appState)
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ summaryCardId, newPosition }: { summaryCardId: number; newPosition: number; summaryCards: SummaryCardType[] }) =>
            Api.updateSummaryCard(summaryCardId, newPosition),
        onMutate: ({ summaryCards }) => {
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            const differentContextCards = (previousData as UserData & { summaryCards: SummaryCardType[] }).summaryCards.filter(
                (c) => c.entryType !== (context === AppContext.Owner ? DashboardEntryType.Owner : DashboardEntryType.Tenant),
            )

            queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData): UserData => {
                const converted = oldData as UserData
                return {
                    ...converted,
                    summaryCards: [...differentContextCards, ...summaryCards],
                }
            })
        },
        onSettled: (_, error) => {
            if (error) {
                invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export const updateChartMutation = () => {
    const queryClient = useQueryClient()
    const { context } = useSelector((state) => state.appState)
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ chartId, newPosition }: { chartId: number; newPosition: number; charts: DashboardChartType[] }) =>
            Api.updateChart(chartId, newPosition),
        onMutate: ({ charts }) => {
            const previousData = queryClient.getQueryData([SimpleQueryIDs.UserData])
            const differentContextCards = (previousData as UserData & { charts: SummaryCardType[] }).dashboardCharts.filter(
                (c) => c.entryType !== (context === AppContext.Owner ? DashboardEntryType.Owner : DashboardEntryType.Tenant),
            )

            queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData): UserData => {
                const converted = oldData as UserData
                return {
                    ...converted,
                    dashboardCharts: [...differentContextCards, ...charts],
                }
            })
        },
        onSettled: (_, error) => {
            if (error) {
                invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export const updateUserNotificationsMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    return useMutation({
        mutationFn: ({ operations }: { operations: jsonpatch.Operation[] }) => Api.updateUserData({ operations }),
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export const updateUser = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ operations, newColumnsOrder }: { operations: jsonpatch.Operation[]; newColumnsOrder?: Record<string, string[]> }) => {
            if (newColumnsOrder) {
                queryClient.setQueryData([SimpleQueryIDs.UserData], (oldData: UserData): UserData => {
                    return {
                        ...oldData,
                        columnOrderByTablePreference: newColumnsOrder,
                    }
                })
            }
            return Api.updateUserData({ operations })
        },
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export const addBankAccountMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: AddBankAccountRequest) => Api.createNewBankAccount({ data }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.BankAccounts])
            }
        },
    })
}

export const updateBankAccountMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ bankAccountId, operations }: { bankAccountId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateBankAccount({
                bankAccountId,
                operations,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.BankAccounts])
            }
        },
    })
}

export const addPropertyToBankAccountMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ bankAccountId, propertyIds }: { bankAccountId: number; propertyIds: number[] }) =>
            Api.addPropertyToABankAccount({
                bankAccountId,
                propertyIds,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.BankAccounts])
            }
        },
    })
}

export const deleteBankAccountMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteBankAccount,
        onSettled: async () => {
            await invalidateQueries([ComplexQueryIDs.BankAccounts])
        },
    })
}

export const deletePropertyFromBankAccountMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ bankAccountId, propertyId }: { bankAccountId: number; propertyId: number }) =>
            Api.deletePropertyFromBankAccount(propertyId, bankAccountId),
        onSettled: async () => {
            await invalidateQueries([ComplexQueryIDs.BankAccounts])
        },
    })
}

export const addCardPaymentDataMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddCardPaymentDataRequest }) => Api.addCardPaymentData(data),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData, SimpleQueryIDs.CardPaymentDetails])
            }
        },
    })
}

export const updateActiveUser = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ userId, accountType }: { userId: string; accountType: AccountType }) => Api.updateActiveUser({ userId, accountType }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.ActiveUsers, ComplexQueryIDs.ActiveUser])
            }
        },
    })
}

export const uploadUserProfilePhoto = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ file }: UploadProfilePictureRequest) => Api.uploadProfilePhoto({ file }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export const deleteUserProfilePhoto = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: () => Api.deleteProfilePhoto(),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserData])
            }
        },
    })
}

export interface UploadProfilePictureRequest {
    file: File
}
